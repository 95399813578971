<template>
  <div style="background: #cdcdcd; height: 100%">
    <div style="min-height: 800px" class="pa-5 pb-0 pt-3">
      <v-card-title v-if="!hasScans" light class="text-h4">
        Das Terminal hat noch keine Geräte erkannt.
        <br />
        Bringe die Geräte, die du buchen möchtest, vor das Terminal.
      </v-card-title>
      <v-card-title v-else-if="!hasSuggestions" light class="text-h4">
        Alle Geräte wurden der Übergabe zugeordnet.
      </v-card-title>
      <AssetListItem
        v-for="asset in assetListCurrentPage"
        :asset="asset"
        :addable="true"
        :key="asset.id"
      />
    </div>
    <!-- Paginator start -->

    <div class="text-center">
      <v-container fluid style="min-height: 170px">
        <v-row justify="center">
          <v-col cols="12" class="flex-row d-flex align-center">
            <v-container class="max-width">
              <v-pagination
                v-model="page"
                dark
                :length="getPageCount()"
                :total-visible="5"
                v-show="getPageCount() > 1"
              />
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Paginator end -->
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import AssetListItem from "@/components/AssetListItem.vue";
import { mapState, mapGetters } from "vuex";
import { ScannedAsset } from "@/sharedTSCode/types/ui-bebos-api";

@Component({
  components: { AssetListItem },
  computed: {
    ...mapGetters([
      "hasHandoverAssignedAssets",
      "hasSuggestions",
      "suggestedAssetList",
    ]),
    ...mapState({
      showDialog: (store: any) => store.handover.suggestion.show,
    }),
  },
})
export default class Handoversuggestions extends Vue {
  public hasHandoverAssignedAssets!: boolean;
  public showDialog!: boolean;

  public hasSuggestions!: boolean;
  public suggestedAssetList!: ScannedAsset[];
  public page = 1;
  public itemsPerPage = 5;
  public assetListCurrentPage: ScannedAsset[] = [];

  async mounted(): Promise<void> {
    this.refreshDisplay();
  }

  @Watch("showDialog")
  refreshDisplay() {
    this.page = 1;
    this.setAssetListCurrentPage();
  }

  getPageCount(): number {
    return Math.ceil(this.suggestedAssetList.length / this.itemsPerPage);
  }

  get hasScans(): boolean {
    return this.hasSuggestions || this.hasHandoverAssignedAssets;
  }

  get assignableAssetsCount(): number {
    return this.suggestedAssetList.filter((asset) => !asset.assigned).length;
  }

  @Watch("assignableAssetsCount")
  public closeSuggestionsAfterAllAreAssigned(newVal: number, oldVal: number) {
    if (newVal === 0) {
      this.$store.commit("hideSuggestions");
    }
  }

  @Watch("page")
  setAssetListCurrentPage(): void {
    this.assetListCurrentPage = this.suggestedAssetList.slice(
      (this.page - 1) * this.itemsPerPage,
      this.page * this.itemsPerPage
    );
  }
}
</script>

<style scoped>
.btn-search-asset {
  height: 80px !important;
  border-radius: 15px !important;
  font-size: 25px;
}
</style>
