import { MsgToUI } from "@/sharedTSCode/types/ui-bebos-api";
import { SensorsCalibState } from "@/types/storeTypes";
import { BebosContext, setSensState } from "./bebos";

type Payload = Record<string, any>;
type ActionInput = { payload: Payload; ctx: BebosContext };
type MessageAction = {
  actionName: MsgToUI["action"];
  action: (input: ActionInput) => void;
};

function globalCommit(ctx: BebosContext, mutation: string, payload: unknown) {
  ctx.commit(mutation, payload, { root: true });
}
function globalDispatch(ctx: BebosContext, action: string, payload: unknown) {
  ctx.dispatch(action, payload, { root: true });
}

export const bebosMessageActions: MessageAction[] = [
  {
    actionName: "calibProcess",
    action: (i) => setSensState(i.ctx, i.payload as SensorsCalibState),
  },
  {
    actionName: "interactionState",
    action: (i) =>
      globalDispatch(i.ctx, "setInteraction", i.payload.interaction),
  },
  {
    actionName: "seenAssets",
    action: (i) =>
      globalDispatch(i.ctx, "updateAssetList", i.payload.assetList),
  },
  {
    actionName: "seenEmployees",
    action: (i) =>
      globalDispatch(i.ctx, "updateEmployeeList", i.payload.employeeList),
  },
  {
    actionName: "connectionInfo",
    action: (i) => globalCommit(i.ctx, "setConnectionInfo", i.payload),
  },
];
