<template>
  <div>
    <div
      id="alertOverlay"
      style="
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 3;
        pointer-events: none;
      "
      :styles="topBasedOnNbr"
      v-if="showAlert && snack.type === 'error'"
    ></div>
    <v-alert
      :value="true"
      :type="snack.type"
      prominent
      tile
      class="title pa-10"
      style="
        left: 10px;
        right: 10px;
        top: -20px;
        box-shadow: rgba(0, 0, 0, 0.4) 0px 15px 25px 5px;
      "
    >
      <v-row align="center">
        <v-col class="grow" style="display: flex; flex-direction: column">
          <span class="text-h4">{{ snack.msg }}</span>
          <span class="text-subtitle-1 mt-1">{{ snack.description }}</span>
        </v-col>
        <v-col class="shrink" v-for="(action, i) in snack.actionList" :key="i">
          <v-btn
            @click="triggerActionAndClose(i)"
            color="rgba(0,0,0, .4)"
            large
            class="mr-5"
          >
            <v-icon x-large class="mr-5" v-if="!!action.icon">{{
              action.icon
            }}</v-icon>
            {{ action.msg }}
          </v-btn>
        </v-col>
        <v-col class="shrink">
          <v-btn icon @click="removeAlert()">
            <v-icon style="opacity: 0.8" x-large>mdi-close-circle</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { Snack } from "@/types/storeTypes";

@Component({ components: {} })
export default class TerminalAlerts extends Vue {
  public showAlert = true;
  @Prop() snack!: Snack;
  @Prop() nbr!: number;

  public triggerActionAndClose(actionIdx: number) {
    this.snack.actionList[actionIdx].action();
    this.removeAlert();
  }

  public removeAlert() {
    this.$store.commit("delSnack", this.snack);
  }
  get topBasedOnNbr(): string {
    return `top: ${this.nbr * 50}px`;
  }
}
</script>

<style scoped>
.v-alert {
  font-size: 2em !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
</style>

<style>
.v-alert__icon,
.v-alert__dismissible .v-icon {
  font-size: 34px !important;
}
</style>
