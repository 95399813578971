import { MsgToBebos, MsgToUI } from "@/sharedTSCode/types/ui-bebos-api";
const BebosURL = "ws://localhost:1340";

export class BebosWs {
  private ws: WebSocket;
  private connectedCallback: () => void;
  private errorCallback: () => void;
  private msgListener: (msg: MsgToUI) => void;
  private _isSetup = false;

  public constructor() {
    if (process.env.VUE_APP_INIT__SKIP_BEBOS) {
      console.info("Connection to Bebos disabled in this environment.");
      this.ws = new WebSocket("ws://ws.ifelse.io"); // dummy server
      this._isSetup = true;
    } else {
      this.ws = new WebSocket(BebosURL);
    }
    this.msgListener = (msg: MsgToUI) => {
      console.warn("Message Listener not set. Received:", msg);
    };
    this.connectedCallback = () => {};
    this.errorCallback = () => {};
  }

  public setupWs({
    msgListener,
    connectedCallback,
    errorCallback,
  }: {
    msgListener?: (msg: MsgToUI) => void;
    connectedCallback?: () => void;
    errorCallback?: () => void;
  } = {}): void {
    this.msgListener = msgListener ?? this.msgListener;
    this.connectedCallback = connectedCallback ?? this.connectedCallback;
    this.errorCallback = errorCallback ?? this.errorCallback;
    if (this._isSetup) {
      return;
    }
    this._isSetup = true;

    this.ws.onopen = () => {
      this.register();
      this.connectedCallback();
    };

    this.ws.onclose = () => {
      setTimeout(() => this.tryReconnect(), 3 * 1000);
      this.errorCallback();
    };
    this.ws.onerror = (ev: Event) => {
      console.error("ws error:", ev);
      this.errorCallback();
    };
    this.ws.onmessage = (msgEvent: MessageEvent) => {
      let bebosMsg = undefined;
      try {
        bebosMsg = JSON.parse(msgEvent.data);
      } catch (e) {
        console.error("← !!", e, msgEvent);
      }
      if (typeof bebosMsg !== "undefined") {
        this.msgListener(bebosMsg);
      }
    };
  }

  public send(msg: MsgToBebos): void {
    if (this.ws.readyState === WebSocket.CONNECTING) {
      setTimeout(() => this.send(msg), 50);
      return;
    }
    if (this.ws.readyState !== WebSocket.OPEN) {
      console.warn("Connection to Bebos lost. Cannot send", msg);
    }
    this.ws.send(JSON.stringify(msg));
  }

  /* private methods */
  private tryReconnect(): void {
    if (
      this.ws.readyState === WebSocket.CONNECTING ||
      this.ws.readyState === WebSocket.OPEN
    ) {
      return;
    }
    this.ws = new WebSocket(BebosURL);
    this._isSetup = false;
    this.setupWs();
    setTimeout(() => this.tryReconnect(), 3 * 1000);
  }

  private register(): void {
    this.send({ action: "registerAsUI", payload: {} });
  }
}
