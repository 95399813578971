import {
  AssetWarning,
  RootState,
  Snack,
  SnackbarState,
} from "@/types/storeTypes";
import router from "@/router";

import {
  ActionContext,
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from "vuex";
import { nextTick } from "vue";

export type SnackbarContext = ActionContext<SnackbarState, RootState>;

const WARNING_PATH = "/warning";
const ROOT_PATH = "/";

const state: SnackbarState = {
  snacks: [],
  assetWarningList: [],
};
const getters: GetterTree<SnackbarState, RootState> = {
  snackbarAlertList(state: SnackbarState): Snack[] {
    return state.snacks.filter((s) => !s.timed);
  },
  snackbarTimedList(state: SnackbarState): Snack[] {
    return state.snacks.filter((s) => s.timed);
  },
  assetWarningList(state: SnackbarState): AssetWarning[] {
    return state.assetWarningList;
  },
  hasErrorsOrWarnings(state: SnackbarState): boolean {
    return state.snacks.filter((s) => s.type === "error").length > 0;
  },
};
const mutations: MutationTree<SnackbarState> = {
  pushSnack(state: SnackbarState, newSnack: Snack): void {
    const fullSnack: Required<Snack> = {
      ttl: 0,
      description: "",
      ...newSnack,
      created: new Date(),
    };
    state.snacks.push(fullSnack);
    if (newSnack.timed) {
      setTimeout(() => delSnack(state, fullSnack), 5000);
    }
  },

  delSnack(state: SnackbarState, snack: Required<Snack>): void {
    delSnack(state, snack);
  },

  resetSnacks(state: SnackbarState): void {
    state.snacks = [];
  },

  cleanSnacks(state: SnackbarState): void {
    state.snacks.map((s) => s.ttl--);
    state.snacks = state.snacks.filter((s) => s.ttl >= 0);
  },

  pushAssetWarning(state: SnackbarState, assetWarning: AssetWarning): void {
    state.assetWarningList.push(assetWarning);
    nextTick() // wait for routing to happen
      .then(() => {
        if (router.currentRoute.path !== WARNING_PATH) {
          router.push(WARNING_PATH);
        }
      });
  },

  removeAssetWarning(state: SnackbarState): void {
    state.assetWarningList.shift();
    nextTick() // wait for routing to happen
      .then(() => {
        goToRootIfNoMoreWarnings(state);
      });
  },

  clearAssetWarningList(state: SnackbarState): void {
    state.assetWarningList.splice(0);
    nextTick() // wait for routing to happen
      .then(() => {
        if (router.currentRoute.path === WARNING_PATH) {
          router.push(ROOT_PATH);
        }
      });
  },
};
const actions: ActionTree<SnackbarState, RootState> = {};

const module: Module<SnackbarState, RootState> = {
  state,
  getters,
  actions,
  mutations,
};

export default module;

// helpers
function delSnack(state: SnackbarState, snack: Required<Snack>) {
  state.snacks = state.snacks.filter((s) => s.created !== snack.created);
}

function goToRootIfNoMoreWarnings(state: SnackbarState) {
  if (state.assetWarningList.length !== 0) return;
  if (router.currentRoute.path === ROOT_PATH) return;
  router.push(ROOT_PATH);
}
