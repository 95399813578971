<template>
  <div :style="cursorStyle">
    <v-app class="background">
      <Header style="height: 5vh" />

      <v-main style="height: 88vh" class="pa-0">
        <MessageHandler />

        <router-view />
      </v-main>

      <v-divider color="white" style="z-index: 4; opacity: 0.6"> </v-divider>

      <Footer style="height: 7vh" />
    </v-app>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import MessageHandler from "@/components/Message/MessageHandler.vue";

@Component({ components: { Footer, Header, MessageHandler } })
export default class App extends Vue {
  async created(): Promise<void> {
    if (!process.env.VUE_APP_INIT__SKIP_FORCE && this.$route.path !== "/init") {
      this.$router.push("/init");
    }
  }
  get cursorStyle(): string {
    const cursorStyle = process.env.VUE_APP_CURSOR;
    if (cursorStyle === undefined) {
      return "cursor: none";
    }
    return `cursor: ${cursorStyle}`;
  }
}
</script>

<style>
#app {
  max-height: 100vh !important;
  font-family: Futura T;
  transition: none !important;
}

.background {
  background-color: #020407 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
}

.v-dialog {
  margin: auto !important;
  background: white !important;
}

.theme--dark.v-pagination .v-pagination__item {
  background: none !important;
  border: 1px solid black !important;
  color: black !important;
}

.theme--dark.v-pagination .v-pagination__item--active {
  color: #ffe22b !important;
  background: rgba(0, 0, 0, 0.3) !important;
  font-weight: bold;
  font-size: 3em;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.5);
  border: 3px solid #ffe22b !important;
  transform: translate(0, -5px) !important;
}

.v-pagination__more {
  font-size: 2.5em !important;
  margin: 1rem !important;
  color: black !important;
}

.v-pagination,
.v-pagination__navigation {
  transition: none !important;
}
</style>

<style lang="scss">
@import "@/styles/variables.scss";
</style>

<style lang="sass">
@import '~vuetify/src/styles/styles.sass'
</style>
