import { WattroNodeConnectionCfg } from "../wattroNode/connect";
import { Asset, Employee, NodeId, Terminal } from "./nodeTypes";

export type MovingScore = number; // a value in 0..1

export enum AssignScoreNamedVal {
  NoData = -2,
  FewReads = -1,
  near = 2,
  touch = 3,
}
interface Scanned {
  assignScore: MovingScore | AssignScoreNamedVal;
  scanned?: boolean;
  assigned: boolean;
  readSinceScan: boolean;
}

export interface ScannedItem extends Scanned {
  id: NodeId;
}
export interface ScannedAsset extends Scanned, Asset {}
export interface ScannedEmployee extends Scanned, Employee {}
export interface InfluxConnectionCfg {
  url: string;
  token: string;
  bucket: string;
  org: string;
}

/* Bebos --> UI */
export type MsgToUI =
  | ConnectionInfoMsg
  | CalibProcessMsg
  | InteractionStateMsg
  | SeenAssets
  | SeenEmployees;

export type Interaction = "noInteraction" | "approaching" | "near";

export interface ConnectionInfoMsg {
  action: "connectionInfo";
  payload: {
    node: WattroNodeConnectionCfg;
    influx: InfluxConnectionCfg;
    terminal: Terminal;
  };
}

export type CalibProcess = {
  total: number;
  individual: Record<string, number>;
};
export interface CalibProcessMsg {
  action: "calibProcess";
  payload: CalibProcess;
}
export interface InteractionStateMsg {
  action: "interactionState";
  payload: { interaction: Interaction };
}

export interface SeenAssets {
  action: "seenAssets";
  payload: { assetList: ScannedItem[] };
}

export interface SeenEmployees {
  action: "seenEmployees";
  payload: { employeeList: ScannedItem[] };
}
/* UI --> Bebos */

export type MsgToBebos =
  | RegisterMsg
  | UIRequestsRescanMsg
  | AnalyzeMsg
  | CommitHandoverMsg
  | MarkStaticMsg
  | GoneInDirtyStateMsg
  | SetScreensaver;

export interface SetScreensaver {
  action: "setScreensaver";
  payload: { active: boolean };
}
export interface RegisterMsg {
  action: "registerAsUI";
  payload: {};
}
export interface UIRequestsRescanMsg {
  action: "requestRescan";
  payload: {};
}
export interface AnalyzeMsg {
  action: "analyze";
  payload: { timestamp: number; msg: string };
}
export interface CommitHandoverMsg {
  action: "commitHandover";
  payload: HandoverInfoMsg;
}

export interface MarkStaticMsg {
  action: "markStatic";
  payload: {
    assetList: ScannedAsset[];
  };
}
export interface GoneInDirtyStateMsg {
  action: "goneInDirtyState";
  payload: {
    assetList: ScannedAsset[];
  };
}
export interface HandoverInfoMsg {
  toStore: boolean;
  toEmployee?: Employee;
  assignedAssetList: ScannedAsset[];
  unassignedAssetList: ScannedAsset[];
}
