const nodeEndpoints = [
  "asset",
  "asset_issue",
  "asset_log",
  "employee",
  "healthchecks",
  "label",
  "label_log",
  "store",
  "tag_type",
  "terminal",
] as const;

export const ApiDataKeys = {
  results: "results",
  count: "count",
  next: "next",
  previous: "previous",
};
const projectEndpoints = ["deployment"] as const;
export type ApiEndpointName =
  | typeof nodeEndpoints[number]
  | typeof projectEndpoints[number];

interface EndpointOpts {
  path?: string;
  setName?: string;
}

class ApiResolver {
  public path: string;
  public setName: string;

  constructor(public name: ApiEndpointName, opts?: EndpointOpts) {
    let scope = "node";
    if (projectEndpoints.includes(name as any)) {
      scope = "project";
    }
    this.path = opts?.path ?? `/${scope}/${name}/`;
    this.setName = opts?.setName ?? `${name}_set`;
  }
}

export const api: Record<ApiEndpointName, ApiResolver> = {
  /* node */
  asset: new ApiResolver("asset"),
  asset_issue: new ApiResolver("asset_issue"),
  asset_log: new ApiResolver("asset_log"),
  employee: new ApiResolver("employee"),
  healthchecks: new ApiResolver("healthchecks", { path: "/healthchecks/" }),
  label: new ApiResolver("label"),
  label_log: new ApiResolver("label_log"),
  store: new ApiResolver("store"),
  tag_type: new ApiResolver("tag_type"),
  terminal: new ApiResolver("terminal"),
  /* project */
  deployment: new ApiResolver("deployment"),
};
