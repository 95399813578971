<template>
  <div>
    <TimedAlert
      v-for="(tAlert, i) in snackbarTimedList"
      :nbr="i"
      :key="`${tAlert.msg}-${i}`"
      :snack="tAlert"
    />
    <Alert
      v-for="(alert, i) in snackbarAlertList"
      :key="`${alert.msg}-${i}`"
      :nbr="snackbarTimedList.length + i"
      :snack="alert"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Alert from "./Alert.vue";
import TimedAlert from "./TimedAlert.vue";
import { mapGetters } from "vuex";

@Component({
  components: { Alert, TimedAlert },
  computed: { ...mapGetters(["snackbarAlertList", "snackbarTimedList"]) },
})
export default class BarHandler extends Vue {}
</script>
