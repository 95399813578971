<template>
  <v-snackbar
    :value="true"
    top
    elevation="25"
    min-height="100"
    max-width="none"
    tile
    style="position: fixed; z-index: 5"
    :style="marginBasedOnNbr"
    :color="snack.type"
    timeout="-1"
  >
    <span class="ml-5 text-h4">
      {{ snack.msg }}
    </span>
    <span class="ml-1 text-subtitle-1">
      {{ snack.description }}
    </span>

    <template v-slot:action="{ attrs }">
      <v-btn
        v-for="(action, i) in snack.actionList"
        :key="i"
        @click="triggerActionAndClose(i)"
        style="font-size: 20px"
        height="80"
        v-bind="attrs"
        class="mr-5 pa-5"
        color="rgba(0,0,0,.3)"
      >
        <v-icon class="mr-4" style="font-size: 30px" v-if="!!action.icon">{{
          action.icon
        }}</v-icon>
        <span>{{ action.msg }}</span>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { Snack } from "@/types/storeTypes";

@Component({ components: {} })
export default class SnackbarCancel extends Vue {
  @Prop() snack!: Snack;
  @Prop() nbr!: number;

  public triggerActionAndClose(actionIdx: number) {
    this.snack.actionList[actionIdx].action();
    this.$store.commit("delSnack", this.snack);
  }
  get marginBasedOnNbr(): string {
    return `margin-top: ${this.nbr * 50}px`;
  }
}
</script>
