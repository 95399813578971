import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import ScanIcon from "@/components/icons/scan.vue";
import NFCIcon from "@/components/icons/nfc.vue";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        yellow: "#ffe22b",
        primary: "#ffe22b",
        success: "#32cd32",
        darkBlue: "#05151b",
        error: "#FF0000",
      },
    },
    options: { variations: false },
  },

  icons: {
    iconfont: "mdi",
    values: {
      scanIcon: {
        component: ScanIcon,
      },
      nfcIcon: {
        component: NFCIcon,
      },
    },
  },
});
