import { RootState, SoundName, SoundState } from "@/types/storeTypes";
import { ActionContext, ActionTree, Module } from "vuex";

type SoundContext = ActionContext<SoundState, RootState>;

const sounds: Record<SoundName, HTMLAudioElement> = {
  ok: new globalThis.Audio(
    require("../../../public/assets/sounds/success.mp3")
  ),
  new: new globalThis.Audio(
    require("../../../public/assets/sounds/new-asset-scanned.mp3")
  ),
  warning: new globalThis.Audio(
    require("../../../public/assets/sounds/error.mp3")
  ),
};

Object.freeze(sounds);

const state: SoundState = {};

const actions: ActionTree<SoundState, RootState> = {
  async playOnce(ctx: SoundContext, soundName: SoundName) {
    await ctx.dispatch("stopPlay");
    sounds[soundName].play();
  },

  async startPlay(ctx: SoundContext, soundName: SoundName) {
    await ctx.dispatch("stopPlay");
    const sound = sounds[soundName];
    sound.loop = true;
    sound.play();
  },

  async stopPlay() {
    for (const [, sound] of Object.entries(sounds)) {
      if (!sound.loop) continue;
      sound.loop = false;
      sound.load();
    }
  },
};

const module: Module<SoundState, RootState> = {
  state,
  actions,
};
export default module;
