export class Lock {
  private locked: boolean;
  private wait_ms: number = 50;

  constructor() {
    this.locked = false;
  }

  public async acquire(): Promise<void> {
    let timeout_s = 5;
    while (this.locked) {
      if (timeout_s > 0) {
        await new Promise((resolve) => setTimeout(resolve, this.wait_ms));
        timeout_s -= this.wait_ms / 1000;
      } else {
        return Promise.reject("Lock timed out");
      }
    }
    this.locked = true;
  }

  public release(): void {
    this.locked = false;
  }
}
