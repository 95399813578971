import { Asset, AssetIssue, Deployment } from "@/sharedTSCode/types/nodeTypes";
import {
  MarkStaticMsg,
  ScannedAsset,
  ScannedItem,
} from "@/sharedTSCode/types/ui-bebos-api";
import {
  AssetLoadingState,
  AssetWarning,
  FixableAsset,
  HandoverProcess,
  HandoverState,
  InfluxMsg,
  Snack,
  SoundName,
} from "@/types/storeTypes";
import { WattroNodeConnection } from "@/sharedTSCode/wattroNode/connect";
import {
  HandoverContext,
  displayHandoverIfNeeded,
  sendToBebos,
} from "./handover";
import { Lock } from "../../utils/Lock";
import { reduceAssetInfo } from "./influx";
import { Point } from "@influxdata/influxdb-client-browser";

const fetchAssetsLock = new Lock();
const newAssetSound: SoundName = "new";

/// assetList manipulation
export function clearAssetList(ctx: HandoverContext): void {
  ctx.state.assetList = [];
  ctx.state.assetsLoading = [];
}

export function archiveAssetList(ctx: HandoverContext) {
  ctx.state.assetsLoading = [];
  ctx.state._assetListHistory = ctx.state.assetList;
  ctx.state.assetList = [];
}

export function restoreAssetListFromHistory(st: HandoverState): void {
  st.assetList = st._assetListHistory;
}

export function setAndFixAssignedForAsset(
  ctx: HandoverContext,
  assignedValue: boolean,
  assetId: number
) {
  const assetIndex = ctx.state.assetList.findIndex((a) => a.id === assetId);
  if (assetIndex < 0) {
    console.error("Asset not found. Failed to unassign asset with id", assetId);
    return;
  }
  const asset = ctx.state.assetList[assetIndex];
  const nowAssigned = !asset.assigned && assignedValue;
  const nowUnassigned = asset.assigned && !assignedValue;

  _set(ctx, assetIndex, {
    ...asset,
    fixedAssigned: true,
    assigned: assignedValue,
  });

  if (nowAssigned) {
    playNewAssetSound(ctx);
    const forceAssignMsg: InfluxMsg = {
      point: new Point().stringField("asset", reduceAssetInfo(asset)),
      msg: "forceAssign",
      context: "setAndFixAssigned",
    };
    ctx.dispatch("logToInflux", forceAssignMsg);
    triggerWarningIfNeeded(ctx, asset);
  }

  if (nowUnassigned) {
    markStatic(ctx, asset);
    pushAssetRemovedSnack(ctx, asset.id);
  }
}

export async function updateAssetListWithScan(
  ctx: HandoverContext,
  newAssetList: ScannedItem[]
): Promise<void> {
  if (newAssetList.length === 0 || isHandoverDone(ctx.state)) return;

  if (ctx.state.process === HandoverProcess.notStarted) {
    ctx.state.process = HandoverProcess.active;
  }
  if (ctx.state.forceAssetAssignedFalse > 0) {
    ctx.state.forceAssetAssignedFalse -= 1;
    newAssetList.forEach((a) => (a.assigned = false));
  }

  const oldAssignedCount = ctx.state.assetList.filter((a) => a.assigned).length;
  await updateAssetsAndSetChangeFlag(ctx, newAssetList);
  const newAssignedCount = ctx.state.assetList.filter((a) => a.assigned).length;

  if (oldAssignedCount >= newAssignedCount) return;
  displayHandoverIfNeeded();
}

export async function triggerWarningIfNeeded(
  ctx: HandoverContext,
  asset: Asset
): Promise<void> {
  if (!asset.flags.checkout_warning) return;

  function relTime(date_str: string): string {
    const rtf = new Intl.RelativeTimeFormat("de", { numeric: "auto" });
    const now = new Date();
    const dat = new Date(date_str);
    const diff_ms = dat.valueOf() - now.valueOf();
    const diff_days = Math.floor(diff_ms / (1000 * 60 * 60 * 24)) + 1;

    return rtf.format(diff_days, "days");
  }

  if (asset.flags.inspection_needed) {
    pushWarning(ctx, {
      asset,
      title: "Prüfung überfällig",
      description: relTime(asset.inspection_ts!),
    });
  }

  if (asset.flags.reservation) {
    pushWarning(ctx, {
      asset,
      title: "Reservierung",
      description: relTime(asset.reservation_ts!),
      reported_by: asset.reservation_employee!,
    });
  }

  if (asset.flags.unresolved_issue) {
    displayUnresolvedIssues(ctx, asset);
  }

  if (asset.flags.has_started_deployment) {
    await displayStartedDeployments(ctx, asset);
  }

  if (asset.flags.is_deactivated) {
    pushWarning(ctx, {
      asset,
      title: "Deaktiviert",
      description: "Dieses Gerät ist deaktiviert. Bitte nicht nutzen.",
    });
  }
}

/// helper functions

async function updateAssetsAndSetChangeFlag(
  ctx: HandoverContext,
  newScannedItemList: ScannedItem[]
): Promise<void> {
  if (ctx.state.process !== HandoverProcess.active) {
    console.log("HandoverProcess not active. dropping update");
    return;
  }
  addNewlyScannedToLoading(ctx, newScannedItemList);

  const scannedAssets: ScannedAsset[] = await getScannedAssetsFromScannedItems(
    ctx,
    newScannedItemList
  );
  if (ctx.state.process !== HandoverProcess.active) {
    console.log(
      "HandoverProcess not active after fetching data. dropping update"
    );
    return;
  }
  for (const asset of scannedAssets) {
    upsertAssetToList(ctx, asset);
  }
}

function addNewlyScannedToLoading(
  ctx: HandoverContext,
  newScannedItemList: ScannedItem[]
) {
  const knownIds = [
    ...ctx.state.assetList.map((a) => a.id),
    ...ctx.state.assetsLoading.map((a) => a.id),
  ];
  const newAssignedScan = newScannedItemList
    .filter((a) => a.assigned)
    .map((a) => a.id)
    .filter((id) => !knownIds.includes(id));

  if (newAssignedScan.length === 0) return;

  newAssignedScan.forEach((id) =>
    ctx.state.assetsLoading.push({ id, loading: true })
  );
  playNewAssetSound(ctx);
}

function upsertAssetToList(ctx: HandoverContext, newAsset: ScannedAsset): void {
  const knownAssetIndex = ctx.state.assetList.findIndex(
    (a) => a.id === newAsset.id
  );
  if (knownAssetIndex < 0) {
    return insertAssetToList(ctx, newAsset);
  }
  updateAssetInList(ctx, knownAssetIndex, newAsset);
}

function updateAssetInList(
  ctx: HandoverContext,
  knownAssetIndex: number,
  newAsset: ScannedAsset
): void {
  const oldAsset = ctx.state.assetList[knownAssetIndex];
  const fixedAssigned = oldAsset.fixedAssigned;
  const assignScore = Math.max(oldAsset.assignScore, newAsset.assignScore);

  let assigned = oldAsset.assigned || newAsset.assigned;
  if (fixedAssigned) {
    assigned = oldAsset.assigned;
  }
  const newlyAssigned = assigned && !oldAsset.assigned;

  _set(ctx, knownAssetIndex, {
    ...newAsset,
    fixedAssigned,
    assigned,
    assignScore,
  });

  if (newlyAssigned) {
    newScannedAssetAssigned(ctx, newAsset);
  }
}

function insertAssetToList(ctx: HandoverContext, newAsset: ScannedAsset): void {
  ctx.state.assetList.push({ ...newAsset, fixedAssigned: false });
  if (newAsset.assigned) {
    newScannedAssetAssigned(ctx, newAsset);
  }
}

async function getScannedAssetsFromScannedItems(
  ctx: HandoverContext,
  newScannedItemList: ScannedItem[]
): Promise<ScannedAsset[]> {
  const fetchedAssets = await fetchMissingAssets(ctx, newScannedItemList);
  return newScannedItemList
    .map((item) => addAssetDataToScan(ctx, fetchedAssets, item))
    .filter((item) => item !== undefined) as ScannedAsset[];
}

async function fetchMissingAssets(
  ctx: HandoverContext,
  newScannedItemList: ScannedItem[]
): Promise<Asset[]> {
  let fetchedAssetData: Asset[] = [];
  try {
    await fetchAssetsLock.acquire();

    const knownAssetIds = ctx.state.assetList.map((a) => a.id);
    const assetIdsToFetch = newScannedItemList
      .map((i) => i.id)
      .filter((id) => !knownAssetIds.includes(id));

    if (assetIdsToFetch.length > 0) {
      const con = tryToGetNodeConnection(ctx);
      if (!con) {
        console.info("'fetchMissingAssets' called but cannot request data.");
        return [];
      }
      fetchedAssetData = await con.getListData<Asset>("asset", {
        id__in: assetIdsToFetch,
      });
    }
  } catch (fetchErr) {
    fetchAssetsLock.release();
    throw fetchErr;
  }
  fetchAssetsLock.release();
  return fetchedAssetData;
}

function addAssetDataToScan(
  ctx: HandoverContext,
  fetchedAssets: Asset[],
  scannedItem: ScannedItem
): ScannedAsset | undefined {
  let assetData: Asset | undefined = undefined;
  assetData = ctx.state.assetList.find((a) => a.id == scannedItem.id);
  if (assetData === undefined) {
    assetData = fetchedAssets.find((a) => a.id == scannedItem.id);
  }
  if (assetData !== undefined) {
    return {
      ...assetData,
      ...scannedItem,
    };
  }
  // failed to find assetData
  console.info(`No data for scanned Item ${JSON.stringify(scannedItem)}.`);
  console.error(
    "Failed to get Asset data for scanned Item. assigned:",
    scannedItem.assigned
  );
  return undefined;
}

function newScannedAssetAssigned(ctx: HandoverContext, newAsset: ScannedAsset) {
  const wasLoading = upsertAssetsLoading(ctx, {
    id: newAsset.id,
    loading: false,
  });
  if (!wasLoading) playNewAssetSound(ctx);
  ctx.state.currentChange.set();
  triggerWarningIfNeeded(ctx, newAsset);
}

function upsertAssetsLoading(
  ctx: HandoverContext,
  loadingState: AssetLoadingState
): boolean {
  /* return true if the asset was loading (aka updated) */
  const loadIdx = ctx.state.assetsLoading.findIndex(
    (l) => l.id === loadingState.id
  );
  const wasLoading = loadIdx >= 0;
  if (wasLoading) {
    ctx.state.assetsLoading.splice(loadIdx, 1, loadingState);
  } else {
    ctx.state.assetsLoading.push(loadingState);
  }
  return wasLoading;
}

async function displayUnresolvedIssues(ctx: HandoverContext, asset: Asset) {
  const con = tryToGetNodeConnection(ctx);
  if (!con) {
    console.info("'displayUnresolvedIssues' called but cannot request data.");
    return;
  }
  const active_issues = await con.getListData<AssetIssue>("asset_issue", {
    asset: asset.id,
    resolved: false,
  });
  active_issues.map((issue) =>
    pushWarning(ctx, {
      asset,
      title: "Defekt",
      description: issue.description,
      reported_by: issue.reported_by,
    })
  );
}

async function displayStartedDeployments(
  ctx: HandoverContext,
  asset: Asset
): Promise<void> {
  const con = tryToGetNodeConnection(ctx);
  if (!con) {
    console.info(
      "'displaystartedDeployments' triggered but cannot request data."
    );
    return;
  }
  pushWarning(ctx, {
    asset,
    title: "Aktiver Einsatz",
    description:
      "Das Gerät ist einem Einsatz zugeordnet, der nicht abgeschlossen wurde. Bitte Nutze die Wattro App um Daten nachzutragen.",
  });
  const of_asset = { asset: asset.id, state: "STARTED" };
  const active_deployments = await con.getListData<Deployment>(
    "deployment",
    of_asset
  );
  for (const depl of active_deployments) {
    if (depl.finished_at !== null) {
      continue;
    }
    depl.finished_at = new Date().toISOString();
    /* await */ con.update<Deployment>("deployment", depl);
  }
}

function markStatic(ctx: HandoverContext, asset: ScannedAsset) {
  const markStaticMsg: MarkStaticMsg = {
    action: "markStatic",
    payload: { assetList: [asset] },
  };
  sendToBebos(ctx, markStaticMsg);
}

function playNewAssetSound(ctx: HandoverContext) {
  ctx.dispatch("playOnce", newAssetSound);
}

function isHandoverDone(state: HandoverState) {
  return [
    HandoverProcess.releaseDone,
    HandoverProcess.takeoverDone,
    HandoverProcess.aborted,
  ].includes(state.process);
}

function pushAssetRemovedSnack(ctx: HandoverContext, assetId: number) {
  const assetRemovedSnack: Snack = {
    type: "success",
    timed: true,
    msg: "Gerät abgewählt",
    actionList: [
      {
        msg: "rückgängig",
        icon: "mdi-refresh",
        action: () => {
          ctx.dispatch("setAssignedForAsset", { assigned: true, assetId });
        },
      },
    ],
  };
  ctx.commit("pushSnack", assetRemovedSnack);
}

function pushWarning(ctx: HandoverContext, warning: AssetWarning) {
  ctx.commit("pushAssetWarning", warning);
}

function _set(
  ctx: HandoverContext,
  assetIndex: number,
  asset: FixableAsset
): void {
  Object.assign(ctx.state.assetList[assetIndex], asset);
}

function tryToGetNodeConnection(
  ctx: HandoverContext
): WattroNodeConnection | null {
  return ctx.rootGetters.tryToGetNodeConnection;
}
