<template>
  <div>
    <div v-if="assetWarning !== undefined">
      <v-col class="d-flex mb-5 align-center">
        <v-col cols="auto" class="pl-0">
          <v-icon size="80">mdi-alert-outline</v-icon>
        </v-col>
        <v-col>
          <span class="text-h1">{{ assetWarning?.title ?? "" }}</span>
        </v-col>
      </v-col>
      <v-col
        style="background: rgba(0, 0, 0, 0.25)"
        class="pa-5 mb-10 rounded-lg"
      >
        <v-row class="d-flex" style="max-height: 800px; overflow: auto">
          <v-col cols="6" class="pr-0">
            <AssetTile
              :asset="assetWarning.asset"
              :deselectable="false"
              :displayFlags="false"
            />
          </v-col>
          <v-col cols="6" class="pl-0">
            <v-row class="ma-0 d-flex align-center">
              <v-col cols="1" class="pl-0 pr-0"
                ><div style="height: 2px; width: 100%; background: white"></div>
              </v-col>
              <v-col
                cols="11"
                style="
                  border: 2px solid white;
                  background-color: rgba(0, 0, 0, 0.2);
                "
                class="rounded-lg"
              >
                <p class="text-h3 truncated-text-block font-weight-bold">
                  {{ assetWarning.description }}
                </p>
                <p class="text-h4 truncated-single-line">
                  Gemeldet von:<br />
                  {{ reported_by }}
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

import { AssetWarning as AssetWarningStoretype } from "@/types/storeTypes";
import AssetTile from "@/components/Tile/Asset.vue";
import BackgroundOverlay from "@/components/Message/BackgroundOverlay.vue";

@Component({ components: { AssetTile, BackgroundOverlay } })
export default class AssetWarning extends Vue {
  @Prop() public assetWarning?: AssetWarningStoretype;
  public reported_by: string = "";

  async mounted(): Promise<void> {
    this.refreshReportedBy();
  }

  async updated(): Promise<void> {
    this.refreshReportedBy();
  }

  async refreshReportedBy(): Promise<void> {
    if (this.assetWarning === undefined) return;
    if (this.assetWarning.reported_by === undefined) {
      this.reported_by = "System";
      return;
    }
    const employee = await this.$store.dispatch(
      "getEmployee",
      this.assetWarning.reported_by
    );
    this.reported_by = employee.name;
  }
}
</script>
<style>
.truncated-text-block {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.truncated-single-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
