import { BebosWs } from "@/utils/bebos_ws";
import {
  CalibProcess,
  ConnectionInfoMsg,
  Interaction,
  ScannedAsset,
} from "@/sharedTSCode/types/ui-bebos-api";
import {
  Asset,
  Employee,
  NodeId,
  Store,
  Terminal,
} from "@/sharedTSCode/types/nodeTypes";
import { TimeoutFlag } from "@/utils/extendableTimer";
import { Point, WriteApi } from "@influxdata/influxdb-client-browser";
import { WattroNodeConnection } from "@/sharedTSCode/wattroNode/connect";

export type RootState = Record<string, never>;
export type BebosStoreState = { ws: BebosWs };
export type NodeConState = {
  connectionState: "connecting" | "connected" | "failed";
  con: WattroNodeConnection | null;
};
export type TerminalState = {
  locked: boolean;
  bebosConnectionState: BebosConnectionState;
  sensorsCalibState: SensorsCalibState;
  interaction: Interaction;
  _goingDirtyTimeout?: number;
  bebosInfo: ConnectionInfoMsg["payload"] | null;
  preferences: {
    force_key_card_login: boolean;
    nfc_enabled: boolean;
  };
  nodeTerminal?: Terminal;
  linkedStore?: Store;
};
export type InfluxState = {
  writeApi: WriteApi | null;
};

export type LogLevel = "critical" | "error" | "warning" | "info" | "debug";
export interface InfluxMsg {
  msg: string;
  context: string;
  level?: LogLevel;
  point?: Point;
}

export enum HandoverProcess {
  notStarted,
  active,
  aborted,
  takeoverDone,
  releaseDone,
}
export type FixableAsset = ScannedAsset & { fixedAssigned: boolean };
export type AssetLoadingState = { id: NodeId; loading: boolean };
export type HandoverState = {
  assetList: FixableAsset[];
  assetsLoading: AssetLoadingState[];
  _assetListHistory: FixableAsset[];
  process: HandoverProcess;
  _justFinished: TimeoutFlag;
  forceAssetAssignedFalse: number;
  currentChange: TimeoutFlag;
  wrongHome: {
    assets: FixableAsset[];
    showError: boolean;
    accept: boolean | null;
  };
};
export type HandoverSuggestionState = {
  show: boolean;
  hint: boolean;
  excludeFromSuggestionIdx: number[];
};

export type EmployeeState = {
  employee: Employee | "annonym";
  loginEvent: TimeoutFlag;
  showLoginDialog: boolean;
  assetListOfEmployee: Asset[];
  assetListLoading: boolean;
  list: Employee[];
  keyCardAction: "login" | "lockTerminal" | "unlockTerminal";
};
export type AssetStoreState = {
  list: Store[];
};
export type SnackbarState = {
  snacks: Required<Snack>[];
  assetWarningList: AssetWarning[];
};
export type SoundState = Record<string, never>;
export type SoundName = "ok" | "new" | "warning";

export type BebosConnectionState = "notConnected" | "connected";
export type SensorsCalibState = "notConnected" | CalibProcess;
export type Snack = {
  type: "error" | "success" | "info";
  timed: boolean;
  msg: string;
  description?: string;
  actionList: ActionBtn[];
  ttl?: number;
  created?: Date;
};
export type ActionBtn = {
  msg?: string;
  icon?: string;
  action: () => void;
};

export type AssetWarning = {
  asset: Asset;
  title: string;
  description: string;
  reported_by?: NodeId;
};
