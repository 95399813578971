<template>
  <v-dialog
    v-model="showDialog"
    overlay-color="black"
    overlay-opacity=".7"
    width="90vw"
    transition="fade-transition"
    @click:outside.prevent="hideSuggestions()"
  >
    <v-card class="pa-0" light>
      <v-app-bar class="pa-0 ma-0 text-h5" light height="100" flat>
        Geräte zur Übergabe hinzufügen
        <v-spacer />
        <v-btn
          fab
          color="darkBlue"
          dark
          class="ma-0 pa-0"
          elevation="0"
          @click="hideSuggestions()"
          ><v-icon x-large>mdi-close</v-icon></v-btn
        >
      </v-app-bar>

      <Suggestions />

      <Help ref="Help" />
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { mapGetters, mapState, mapMutations } from "vuex";

import Suggestions from "@/components/HandoverAdd/Suggestions.vue";
import Help from "@/components/HandoverAdd/Help.vue";

@Component({
  components: { Suggestions, Help },
  computed: {
    ...mapState({
      showDialog: (store: any) => store.handover.suggestion.show,
    }),
  },
  methods: {
    ...mapMutations(["hideSuggestions"]),
  },
})
export default class HandoverAddDialog extends Vue {
  public showDialog!: boolean;
}
</script>
