import { RootState } from "@/types/storeTypes";
import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";

import bebos from "./modules/bebos";
import handover from "./modules/handover";
import message from "./modules/message";
import sound from "./modules/sound";
import storeModule from "./modules/store";
import terminal from "./modules/terminal";
import employee from "./modules/employee";
import nodeCon from "./modules/nodeCon";
import influx from "./modules/influx";

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  modules: {
    bebos,
    employee,
    handover,
    influx,
    nodeCon,
    snackbar: message,
    sound,
    store: storeModule,
    terminal,
  },
};

// eslint-disable-next-line import/no-named-as-default-member
export default new Vuex.Store(store);
