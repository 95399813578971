import { posthog } from "posthog-js";

export default {
  install(Vue: any, _options: any) {
    const key = process.env.VUE_APP_POSTHOG__KEY;
    if (key) {
      Vue.prototype.$posthog = posthog.init(key, {
        api_host: "https://eu.posthog.com",
        capture_pageview: false, // needs to be done explicitly in an SPA
        disable_session_recording: true, // wait for terminal info to start
      });
    } else {
      console.warn("No Posthog key given");
    }
  },
};
