<template>
  <div
    style="height: 100%; overflow: hidden; background: #e6e6e6"
    class="pa-5 pt-0"
  >
    <v-overlay
      :value="!$store.getters['isActive']"
      opacity="0.6"
      z-index="2000"
      style="pointer-events: none"
    />
    <div style="height: 5%; display: flex; align-items: center; color: #020407">
      <h1>Halte deine Geräte vor die Markierung am Terminal</h1>
    </div>
    <div style="height: 95%">
      <div class="fill-height pa-0">
        <v-dialog
          v-model="showError"
          overlay-color="black"
          overlay-opacity=".95"
          persistent
          transition="fade-transition"
        >
          <v-card color="error" style="overflow: hidden">
            <v-card-title class="text-h1 pa-10 pl-5">
              <v-icon class="mr-10">mdi-alert</v-icon>
              Übergabe nicht möglich
            </v-card-title>
            <v-divider />
            <v-card-text class="text-h2 pt-5">
              Die Übergabe von mehr als 6 Geräten ist derzeit noch nicht
              möglich.
              <br /><br />
              Möchtest du mehr als 6 Geräte mitnehmen, musst du mehrere
              Übergaben durchführen.
              <br /><br />
              Bitte Geräte verräumen und mit maximal 6 zurück kommen.
            </v-card-text>
            <br /><br />
            <v-row class="pa-5">
              <v-col>
                <v-btn
                  block
                  x-large
                  class="text-h3 pa-10"
                  @click="cancelHandoverAndGoHome()"
                >
                  abbrechen
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
        <WrongHomeDialog></WrongHomeDialog>
        <v-container
          fluid
          class="fill-height pa-5 pt-7 pb-7 ma-0"
          style="
            height: 85%;
            width: 100%;
            display: flex;
            flex-direction: column;
            background: #cdcdcd;
            overflow: auto;
          "
        >
          <v-row style="height: 100%; width: 100%">
            <div
              class="pa-2 tile-grid"
              v-for="n in emptyTilesCount"
              :key="`empty-${n}`"
            >
              <EmptyTile :active="false" />
            </div>
            <div v-if="showEmptyTiles" class="pa-2 tile-grid">
              <EmptyTile :active="true" />
            </div>
            <div
              class="pa-2 tile-grid"
              v-for="asset in assignedAssetList"
              :key="asset.id"
            >
              <AssetTile :asset="asset" :deselectable="true" />
            </div>
            <div
              class="pa-2 tile-grid"
              v-for="n in assetSkelCount"
              :key="`skel-${n}`"
            >
              <AssetSkel />
            </div>
          </v-row>
        </v-container>

        <v-container fluid style="height: 15%; display: flex">
          <HandoverBtns :active="isHandoverPossible" :btnConfig="btnConfig" />
        </v-container>
      </div>
    </div>
    <HandoverAddDialog />
    <EmployeeLoginDialog />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { NavigationGuardNext } from "vue-router/types/router";

import AssetTile from "@/components/Tile/Asset.vue";
import EmptyTile from "@/components/Tile/Empty.vue";
import AssetSkel from "@/components/Tile/AssetSkel.vue";
import HandoverBtns from "@/components/HandoverBtns.vue";
import HandoverAddDialog from "@/components/HandoverAdd/Dialog.vue";
import WrongHomeDialog from "@/components/Message/WrongHomeDialog.vue";
import EmployeeLoginDialog from "@/components/Employee/LoginDialog.vue";

import { Asset } from "@/sharedTSCode/types/nodeTypes";
import { CancelReason } from "@/store/modules/handover";

@Component({
  components: {
    AssetTile,
    AssetSkel,
    EmptyTile,
    HandoverBtns,
    HandoverAddDialog,
    EmployeeLoginDialog,
    WrongHomeDialog,
  },
  computed: {
    ...mapGetters([
      "assignedAssetList",
      "isHandoverPossible",
      "assetSkelCount",
    ]),
  },
  beforeRouteEnter(to, from, next: NavigationGuardNext<HandoverUpdate>) {
    next((vm: HandoverUpdate) => {
      vm.$store.dispatch("enterHandover");
    });
  },
})
export default class HandoverUpdate extends Vue {
  public assignedAssetList!: Asset[];
  public assetSkelCount!: number;
  public isHandoverPossible!: boolean;
  public btnConfig = {
    takeover: {
      text: "mitnehmen",
      icon: "mdi-account",
    },
    release: {
      text: "abgeben",
      icon: "mdi-home",
    },
    first: "takeover",
  };

  async mounted(): Promise<void> {
    this.$store.commit("logout");
    this.btnConfig = await this.$store.dispatch("getHandoverBtnConfig");
  }

  get emptyTilesCount(): number {
    return Math.max(
      0,
      6 - 1 - this.assignedAssetList.length - this.assetSkelCount
    );
  }

  get showEmptyTiles(): boolean {
    return this.assignedAssetList.length < 6;
  }

  get showError(): boolean {
    return this.assignedAssetList.length > 6;
  }

  public async cancelHandoverAndGoHome(): Promise<void> {
    const cancelReason: CancelReason = "explicitUI";
    this.$store.dispatch("cancelHandover", {
      cancelReason,
    });
    this.$router.push("/home");
  }
}
</script>

<style scoped>
.tile-grid {
  height: calc(100% / 3) !important;
  width: 50% !important;
}
</style>
