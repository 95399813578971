<template>
  <div style="height: 100%">
    <v-container
      v-if="!isLoggedIn"
      fluid
      style="height: 100%; background: #cdcdcd"
      class="pa-6 justify-center d-flex align-center flex-column"
      @click="$store.dispatch('showEmployeeLoginDialog')"
    >
      <v-icon size="150" class="grey--text">
        mdi-account-question-outline
      </v-icon>
      <span class="text-h4 grey--text">Nicht angemeldet</span>
    </v-container>
    <v-container
      v-else
      fluid
      style="height: 100%; background: #cdcdcd"
      class="pa-0 d-flex flex-column justify-space-between"
    >
      <v-container
        v-if="assetListOfEmployee.length > 0"
        fluid
        style="overflow: hidden"
        class="pa-6"
      >
        <AssetListItem
          v-for="asset in assetListCurrentPage"
          :asset="asset"
          :key="asset.id"
        />
        <div v-if="assetListLoading">
          <v-progress-circular class="grey--text ml-3" :indeterminate="true" />
          <span class="text-h4 grey--text ml-15">
            <v-icon class="grey--text mr-15"> mdi-cube-send</v-icon> Weitere
            Einträge werden geladen.
          </span>
        </div>
      </v-container>
      <v-container
        v-else-if="assetListLoading"
        fluid
        style="height: 100%"
        class="pa-6 justify-center d-flex align-center flex-column"
      >
        <v-icon size="150" class="grey--text"> mdi-cube-send </v-icon>
        <span class="text-h4 grey--text"> Inventar wird geladen. </span>
        <v-progress-circular :indeterminate="true" />
      </v-container>
      <v-container
        v-else
        fluid
        style="height: 100%"
        class="pa-6 justify-center d-flex align-center flex-column"
      >
        <v-icon size="150" class="grey--text"> mdi-cube-off-outline </v-icon>
        <span class="text-h4 grey--text"> Nichts in deinem Inventar </span>
      </v-container>
      <!-- Paginator start -->
      <div class="text-center">
        <v-container fluid>
          <v-row justify="center">
            <v-col cols="12" class="flex-row d-flex align-center">
              <v-container class="max-width">
                <v-pagination
                  v-model="page"
                  dark
                  :length="getPageCount()"
                  :total-visible="5"
                  v-show="getPageCount() > 1"
                />
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- Paginator end -->
    </v-container>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import AssetListItem from "@/components/AssetListItem.vue";
import { mapState, mapGetters } from "vuex";

import { Asset, Employee } from "@/sharedTSCode/types/nodeTypes";

@Component({
  components: { AssetListItem },
  computed: {
    ...mapState({
      assetListOfEmployee: (store: any) => store.employee.assetListOfEmployee,
      assetListLoading: (store: any) => store.employee.assetListLoading,
      employee: (store: any) => store.employee.employee,
    }),
    ...mapGetters(["isLoggedIn"]),
  },
})
export default class EmployeeInventory extends Vue {
  public employee!: Employee | "annonym";
  public assetListOfEmployee!: Asset[];
  public assetListLoading!: boolean;
  public isLoggedIn!: boolean;

  public page = 1;
  public itemsPerPage = 5;
  public assetListCurrentPage: Asset[] = [];

  created(): void {
    this.setAssetListCurrentPage();
    this.$store.dispatch("refreshAssetListOfEmployee");
  }

  @Watch("page")
  @Watch("assetListOfEmployee")
  private setAssetListCurrentPage(): void {
    this.assetListCurrentPage = this.assetListOfEmployee.slice(
      (this.page - 1) * this.itemsPerPage,
      this.page * this.itemsPerPage
    );
  }

  getPageCount(): number {
    return Math.ceil(this.assetListOfEmployee.length / this.itemsPerPage);
  }
}
</script>
