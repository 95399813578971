<template>
  <div
    style="height: 100%; overflow: hidden; background: #e6e6e6"
    class="pa-5 pt-0"
  >
    <div style="height: 5%; display: flex; align-items: center; color: #020407">
      <h1>
        Nicht bestätigte Meldungen:
        {{ assetWarningList.length }}
      </h1>
    </div>
    <div style="height: 95%">
      <div class="fill-height pa-0">
        <v-container
          fluid
          class="fill-height pa-5 pt-7 pb-7 ma-0"
          style="
            height: 85%;
            width: 100%
            display: flex;
            flex-direction: column;
            background: #cdcdcd;
            overflow: auto
          "
        >
          <v-row style="height: 100%; width: 100%">
            <v-container fluid style="height: 100%">
              <v-row
                class="elevation-12 no-gutters d-flex flex-column white--text px-10 py-5"
                :class="{ warning: isReservation, error: !isReservation }"
                style="heigth: 100%"
              >
                <v-col>
                  <AssetWarning :assetWarning="currentWarning" />
                </v-col>
                <v-col>
                  <v-btn @click="acceptClickHandler()" x-large block>
                    <v-icon>mdi-check</v-icon>verstanden
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-row>
        </v-container>

        <v-container fluid style="height: 15%; display: flex">
          <HandoverBtns :active="false" :btnConfig="btnConfig" />
        </v-container>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import AssetWarning from "@/components/Message/AssetWarning.vue";
import HandoverBtns from "@/components/HandoverBtns.vue";
import { AssetWarning as AssetWarningStoretype } from "@/types/storeTypes";

@Component({
  components: { AssetWarning, HandoverBtns },
  computed: { ...mapGetters(["assetWarningList"]) },
})
export default class BarHandler extends Vue {
  public assetWarningList!: AssetWarningStoretype[];

  public btnConfig = {
    takeover: { text: "mitnehmen", icon: "mdi-account" },
    release: { text: "abgeben", icon: "mdi-home" },
    first: "takeover",
  };

  public acceptClickHandler(): void {
    this.$store.commit("removeAssetWarning");
  }

  get currentWarning(): AssetWarningStoretype {
    return this.assetWarningList[0];
  }

  get isReservation(): boolean {
    return (this.currentWarning?.title ?? "") === "Reservierung";
  }
}
</script>

<style>
.btn-yellow {
  background: #ffe22b !important;
  color: #05151b !important;
}
</style>
