<template>
  <v-row class="pt-5">
    <v-col
      cols="5"
      class="pa-0"
      :order="takeoverOrder"
      style="position: relative"
    >
      <v-btn
        light
        @click="$store.dispatch('commitHandover', 'takeOver')"
        :class="{ 'btn-handover': active }"
        class="d-flex flex-column align-center justify-center"
        rounded
        :disabled="!active"
        block
        style="width: 100%; height: 100%"
      >
        <div>
          <v-icon style="font-size: 50px">
            {{ btnConfig.takeover.icon }}</v-icon
          >
          <h2 class="text-h2 py-0">{{ btnConfig.takeover.text }}</h2>
        </div>
      </v-btn>
    </v-col>
    <v-col
      cols="2"
      class="pa-5"
      style="display: flex; justify-content: center; align-items: center"
    >
      <v-btn
        block
        rounded
        class="btn-cancel"
        height="120px"
        @click="cancelHandoverAndGoHome()"
      >
        <div
          style="
            font-size: 1em;
            display: flex;
            align-items: center;
            flex-direction: column;
          "
        >
          <v-icon style="font-size: 3em" class="mb-2"> mdi-cancel </v-icon>
          <span> abbrechen </span>
        </div>
      </v-btn>
    </v-col>
    <v-col cols="5" class="pa-0" :order="releaseOrder">
      <v-btn
        light
        @click="$store.dispatch('commitHandover', 'release')"
        :class="{ 'btn-handover': active }"
        rounded
        :disabled="!active"
        block
        style="height: 100%; width: 100%"
        class="d-flex flex-column justify-center align-center"
      >
        <div>
          <v-icon style="font-size: 50px">{{ btnConfig.release.icon }}</v-icon>
          <h2 class="text-h2 my-0">{{ btnConfig.release.text }}</h2>
          <span
            class="text-h5 my-0 d-inline-block text-truncate"
            style="max-width: 400px"
          >
            {{ terminalStoreName }}
          </span>
        </div>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { CancelReason } from "@/store/modules/handover";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ components: {} })
export default class HandoverBtns extends Vue {
  @Prop() public active!: boolean;
  @Prop() public btnConfig!: {
    takeover: { text: string; icon: string };
    release: { text: string; icon: string };
    first: string;
  };

  get takeoverOrder(): string {
    return this.btnConfig.first === "takeover" ? "first" : "last";
  }

  get releaseOrder(): string {
    return this.btnConfig.first === "takeover" ? "last" : "first";
  }

  public async cancelHandoverAndGoHome(): Promise<void> {
    const cancelReason: CancelReason = "explicitUI";
    this.$store.dispatch("cancelHandover", { cancelReason });
    this.$router.push("/home");
  }

  get nfcEnabled(): boolean {
    return this.$store.state.terminal.preferences.nfc_enabled;
  }

  get terminalStoreName(): string {
    return this.$store.state.terminal.linkedStore.name ?? "";
  }
}
</script>

<style>
.btn-handover {
  background: #ffe22b !important;
  color: #05151b !important;
}
.hover-badge {
  position: absolute;
  margin-inline: 10px;
  top: -20px;
  right: 0;
  left: 0;
  color: black;
  padding: 5px 10px;
  border-radius: 10px !important;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.5) !important;
  background-color: white;
}
.hover-badge .v-banner__wrapper {
  padding: 0px !important;
  margin: 0px !important;
}
.v-application .theme--light .primary--text {
  color: #05151b !important;
}

v-btn__content {
  max-width: 10px !important;
}
</style>
