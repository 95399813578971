<template>
  <div
    class="mb-5 d-flex align-center"
    @click="$store.dispatch('login', employee)"
  >
    <v-card tile style="width: 100%" light flat>
      <v-list-item>
        <EmployeeAvatar :employee="employee" />
        <v-list-item-content>
          <v-list-item-title class="text-h4 pl-6">
            {{ employee.name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-card>
    <div id="connector--small"></div>
    <v-sheet
      width="150"
      height="100"
      class="rounded-lg d-flex flex-column align-center justify-center"
      text-color="dark"
      elevation="0"
      color="yellow"
    >
      <v-icon class="darkBlue--text">mdi-account-check</v-icon>
      <span class="text-caption darkBlue--text">anmelden</span>
    </v-sheet>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { Employee } from "src/sharedTSCode/types/nodeTypes";
import EmployeeAvatar from "@/components/Employee/Avatar.vue";

@Component({ components: { EmployeeAvatar } })
export default class EmployeeListItem extends Vue {
  @Prop() employee!: Employee;
}
</script>

<style scoped>
.v-card {
  display: flex;
  overflow: hidden;
}
.v-card,
.v-btn {
  height: 110px !important;
}
.v-btn {
  color: #05151b;
}
#connector--small {
  height: 80px;
  background: white;
  width: 5px;
}
</style>
