import router from "@/router";
import { Asset, Employee, NodeId } from "@/sharedTSCode/types/nodeTypes";
import {
  AssignScoreNamedVal,
  ScannedItem,
} from "@/sharedTSCode/types/ui-bebos-api";
import { WattroNodeConnection } from "@/sharedTSCode/wattroNode/connect";
import { EmployeeState, RootState, Snack } from "@/types/storeTypes";
import { TimeoutFlag } from "@/utils/extendableTimer";
import _ from "lodash";

import {
  ActionContext,
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from "vuex";
import { wait_ms } from "@/store/helpers";

export type EmployeeContext = ActionContext<EmployeeState, RootState>;

export const userAbortedLoginError = new Error("User aborted login.");

const state: EmployeeState = {
  employee: "annonym",
  loginEvent: new TimeoutFlag(1000, false),
  showLoginDialog: false,
  assetListOfEmployee: [],
  assetListLoading: false,
  keyCardAction: "login",
  list: [],
};

const getters: GetterTree<EmployeeState, RootState> = {
  currentEmployee(state: EmployeeState): EmployeeState["employee"] {
    return state.employee;
  },
  isLoggedIn(state: EmployeeState): boolean {
    return state.employee !== "annonym";
  },
};
const mutations: MutationTree<EmployeeState> = {
  logout(state: EmployeeState): void {
    state.employee = "annonym";
    state.assetListOfEmployee = [];
    state.keyCardAction = "login";
  },
  _setEmployee(state: EmployeeState, employee: Employee): void {
    state.employee = employee;
  },

  hideEmployeeLoginDialog(state: EmployeeState): void {
    state.showLoginDialog = false;
  },

  setKeyCardAction(
    state: EmployeeState,
    action: EmployeeState["keyCardAction"]
  ): void {
    state.keyCardAction = action;
  },
};

const actions: ActionTree<EmployeeState, RootState> = {
  async refreshEmployeeList(ctx: EmployeeContext): Promise<void> {
    throttledRefresh(ctx);
  },

  async forceRefreshEmployeeList(ctx: EmployeeContext): Promise<void> {
    refreshEmployeeList(ctx);
  },

  async showEmployeeLoginDialog(ctx: EmployeeContext): Promise<void> {
    state.showLoginDialog = true;
    /*await*/
    ctx.dispatch("refreshEmployeeList");
  },

  async login(ctx: EmployeeContext, employee: Employee): Promise<void> {
    ctx.state.employee = employee;
    /*await*/
    loadEmployeeAssetList(ctx, employee);
    ctx.commit("hideEmployeeLoginDialog");
  },

  async refreshAssetListOfEmployee(ctx: EmployeeContext): Promise<void> {
    if (ctx.state.employee === "annonym") return;
    await loadEmployeeAssetList(ctx, ctx.state.employee);
  },

  async makeSureIsLoggedIn(ctx: EmployeeContext): Promise<void> {
    if (ctx.state.employee !== "annonym") return;
    ctx.dispatch("showEmployeeLoginDialog");
    await resolveAfterLogin(ctx);
    ctx.commit("hideEmployeeLoginDialog");
  },

  async updateEmployeeList(
    ctx: EmployeeContext,
    employeeList: ScannedItem[]
  ): Promise<void> {
    const touchEmployees = employeeList.filter(
      (u) => u.assignScore == AssignScoreNamedVal.touch
    );
    if (touchEmployees.length === 0) {
      console.debug(
        `${employeeList.length} Employees scanned but non in touch range.`
      );
      return;
    }
    if (touchEmployees.length !== 1) {
      console.warn(
        `Ambigious keyCardEvent data: ${touchEmployees.length} employees in touch range.`
      );
      return;
    }
    const employee = await ctx.dispatch("getEmployee", touchEmployees[0].id);
    await keyCardEvent(ctx, employee);
  },

  async getEmployee(ctx: EmployeeContext, id: NodeId): Promise<Employee> {
    const storedEmployee = ctx.state.list.find((e) => e.id === id);
    if (storedEmployee) return storedEmployee;
    const con = tryToGetNodeConnection(ctx);
    if (!con) {
      console.info("updateEmployeeList cannot request Employee.");
      return Promise.reject("No request possible.");
    }
    const fetchedEmployee = await con.getById<Employee>("employee", id);
    if (!fetchedEmployee)
      return Promise.reject(`No Employee with id ${id} found.`);
    ctx.state.list.push(fetchedEmployee);
    return fetchedEmployee;
  },
};

async function keyCardEvent(
  ctx: EmployeeContext,
  newEmployee: Employee
): Promise<void> {
  switch (ctx.state.keyCardAction) {
    case "login":
      return keyCardActionLogin(ctx, newEmployee);
    case "lockTerminal":
    case "unlockTerminal":
      if (!newEmployee.is_storekeeper) {
        pushNotAStorekeeperSnack(newEmployee, ctx);
      } else {
        await ctx.dispatch(ctx.state.keyCardAction, null, { root: true });
      }
      ctx.state.keyCardAction = "login";
      break;
    default:
      console.warn(`No action for '${ctx.state.keyCardAction}'.`);
      break;
  }
}

async function keyCardActionLogin(ctx: EmployeeContext, newEmployee: Employee) {
  setKeyCardEventUIFlag(ctx);
  if (!ctx.getters.isLoggedIn) {
    await ctx.dispatch("login", newEmployee);
    await setLogInSnack(ctx, newEmployee);
  }
  if (
    ctx.rootGetters.hasHandoverAssignedAssets &&
    router.currentRoute.fullPath !== "/warning"
  ) {
    await moveToHomeWithSideEffect(ctx);
  }
}

function pushNotAStorekeeperSnack(newEmployee: Employee, ctx: EmployeeContext) {
  const notAStorkeeper: Snack = {
    type: "error",
    timed: true,
    msg: `"${newEmployee.name}" ist kein Lagerverwalter`,
    actionList: [],
  };
  ctx.commit("pushSnack", notAStorkeeper);
}

function setKeyCardEventUIFlag(ctx: EmployeeContext): void {
  ctx.state.loginEvent.set();
}

async function moveToHomeWithSideEffect(ctx: EmployeeContext): Promise<void> {
  if (ctx.rootGetters.isHandoverActive)
    return ctx.dispatch("commitHandover", "takeOver");
  if (router.currentRoute.fullPath !== "/home") await router.push("/home");
}

async function setLogInSnack(
  ctx: EmployeeContext,
  newEmployee: Employee
): Promise<void> {
  const employeeAddedSnack: Snack = {
    type: "success",
    timed: true,
    msg: `Hallo ${newEmployee.name}.`,
    actionList: [
      {
        msg: "abmelden",
        icon: "mdi-logout",
        action: () => ctx.commit("logout"),
      },
    ],
  };
  ctx.commit("pushSnack", employeeAddedSnack);
}

async function loadEmployeeAssetList(
  ctx: EmployeeContext,
  employee: Employee
): Promise<void> {
  ctx.state.assetListLoading = true;
  try {
    const con = tryToGetNodeConnection(ctx);
    if (!con) {
      console.info("loadEmployeeAssetList cannot request data.");
      return;
    }
    const assetList = await con.getListData<Asset>("asset", {
      _assigned_employee: employee.id,
    });
    for (const newAsset of assetList) {
      addOrUpdateAsset(state, newAsset);
    }
  } finally {
    ctx.state.assetListLoading = false;
  }
}

function tryToGetNodeConnection(
  ctx: EmployeeContext
): WattroNodeConnection | null {
  return ctx.rootGetters.tryToGetNodeConnection;
}

async function refreshEmployeeList(ctx: EmployeeContext): Promise<void> {
  if (ctx.rootGetters.force_key_card_login) {
    console.log("KeyCard login forced. Refreshing aborted.");
    return;
  }
  console.time("refreshing employee list");
  const con = tryToGetNodeConnection(ctx);
  if (!con) {
    console.info("refreshEmployeeList called but cannot request data.");
    return;
  }
  const employeeList = await con.getListData<Employee>("employee");
  ctx.state.list = employeeList.sort((a, b) =>
    a.name.localeCompare(b.name, "de")
  );
  console.timeEnd("refreshing employee list");
}

// Invoke refresh when action is called, but not more than once every minute.
const one_minute_in_ms = 1000 * 60 * 1;
const one_shot_opts = { trailing: false };
const throttledRefresh = _.throttle(
  refreshEmployeeList,
  one_minute_in_ms,
  one_shot_opts
);

function addOrUpdateAsset(state: EmployeeState, newAsset: Asset) {
  const index = state.assetListOfEmployee.findIndex(
    (a) => a.id === newAsset.id
  );
  if (index < 0) {
    state.assetListOfEmployee.push(newAsset);
  } else {
    state.assetListOfEmployee[index] = newAsset;
  }
}

async function resolveAfterLogin(ctx: EmployeeContext): Promise<void> {
  while (!ctx.state.showLoginDialog) {
    await wait_ms(25);
  }
  while (ctx.state.employee === "annonym" && ctx.state.showLoginDialog) {
    await wait_ms(25);
  }
  if (ctx.state.employee !== "annonym") return;
  throw userAbortedLoginError;
}

const module: Module<EmployeeState, RootState> = {
  state,
  getters,
  actions,
  mutations,
};
export default module;
