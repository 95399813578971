<template>
  <v-container class="pa-0" rounded-lg style="height: 100%; aspect-ratio: 1/1">
    <v-app-bar
      flat
      class="pa-0 border-radius-top-15px"
      style="height: 10%; background: white; z-index: 2"
    >
      <v-spacer></v-spacer>
    </v-app-bar>

    <div style="height: 65%; background: white" class="pa-2">
      <v-img
        :src="getAssetImage()"
        contain
        style="height: 100%; margin-top: -10px"
      />
    </div>

    <v-row
      class="pl-5 pr-5 ma-0 border-radius-bottom-15px"
      style="height: 20%; background: #05151b"
    >
      <v-col class="d-flex align-end pa-0" cols="12">
        <h3 style="font-size: 1.75em" class="d-block text-truncate">
          {{ asset.name }}
        </h3>
      </v-col>
      <v-col class="d-flex pa-0" cols="12"
        ><p class="text-h5 ma-0 d-block text-truncate">
          {{ asset.human_id }}
        </p></v-col
      >
    </v-row>
    <!-- show home info -->
    <div class="d-flex justify-center pa-0">
      <div style="height: 4px; width: 70%" class="grey lighten-1"></div>
    </div>
    <div class="white rounded-lg pa-2 black--text d-flex flex-row align-center">
      <div
        v-if="asset.home_store"
        class="rounded-pill blue-grey lighten-2 pa-2 me-2 d-flex align-center"
      >
        <v-icon size="25" color="white">mdi-home-import-outline</v-icon>
      </div>
      <div
        v-else
        class="rounded-pill blue lighten-4 pa-1 me-2 d-flex align-center"
      >
        <v-icon size="25" color="grey darken-4"
          >mdi-account-arrow-left-outline</v-icon
        >
      </div>
      <span class="text-h6 d-block text-truncate"> {{ home }} </span>
    </div>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { Asset, Employee, Store } from "@/sharedTSCode/types/nodeTypes";
import AssetFlag from "@/components/Tile/Flag.vue";
import { WattroNodeConnection } from "@/sharedTSCode/wattroNode/connect";

@Component({ components: { AssetFlag } })
export default class WrongHomeTile extends Vue {
  @Prop() public asset!: Asset;
  public home: string = "";

  async created() {
    const con: WattroNodeConnection =
      this.$store.getters.tryToGetNodeConnection;
    this.home = "Lade...";
    if (!con) return;
    if (this.asset.home_store) {
      const store: Store | undefined = await con.getById<Store>(
        "store",
        this.asset.home_store
      );
      if (!store) return;
      this.home = "Lagerort: " + store.name;
      return;
    }
    if (this.asset.home_employee) {
      const employee: Employee | undefined = await con.getById<Employee>(
        "employee",
        this.asset.home_employee
      );
      if (!employee) return;
      this.home = "Zurück an " + employee.name;
      return;
    }
  }

  public getAssetImage(): string {
    return this.asset.img["main"][0].picture.large;
  }

  get correctHome(): string {
    if (this.asset.home_store) {
      return `Lagerort: ${this.asset.home_store}.`;
    }

    return `Zurück an ${this.asset.home_employee}. (persönliches Inventar)`;
  }
}
</script>

<style scoped>
.border-radius-top-15px {
  border-top-right-radius: 15px !important;
  border-top-left-radius: 15px !important;
}

.border-radius-bottom-15px {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
</style>
