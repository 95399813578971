<template>
  <v-bottom-navigation color="#ffe22b" grow background-color="transparent">
    <v-btn
      v-for="link in links"
      :key="link.text"
      router
      :to="link.route"
      style="
        background-color: transparent;
        height: 100%;
        max-width: none;
        z-index: 4;
      "
    >
      <span class="text-h6">{{ link.text }}</span>
      <v-icon v-if="link.icon" class="text-h4 mb-2">{{ link.icon }}</v-icon>
      <v-icon v-else>$scanIcon</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component({ components: {} })
export default class Footer extends Vue {
  data() {
    return {
      links: [
        { icon: "mdi-home-variant-outline", text: "Home", route: "/home" },
        {
          text: "Scan",
          route: "/",
        },
        { icon: "mdi-magnify", text: "Suche", route: "/suche" },
        { icon: "mdi-help-circle-outline", text: "Hilfe", route: "/hilfe" },
      ],
    };
  }
}
</script>
