interface LayoutItem {
  layout: { [layoutName: string]: string[] };
}

export const wattroDisplayKeys = {
  "{bksp}": "⟻",
  "{enter}": "↵",
  "{space}": "\t\t\t\t",
};

export const wattroLayout: LayoutItem = {
  layout: {
    default: [
      "^ 1 2 3 4 5 6 7 8 9 0 ß \u00B4 {bksp}",
      "q w e r t z u i o p ü +",
      "a s d f g h j k l ö ä # {enter}",
      "< y x c v b n m , . -",
      "@ {space}",
    ],
    shift: [
      '° ! " § $ % & / ( ) = ? ` {bksp}',
      "Q W E R T Z U I O P Ü *",
      "A S D F G H J K L Ö Ä ' {enter}",
      "> Y X C V B N M ; : _",
      "@ {space} {shift}",
    ],
  },
};
