import { RootState, InfluxState, InfluxMsg } from "@/types/storeTypes";
import { ActionContext, ActionTree, Module } from "vuex";
type InfluxContext = ActionContext<InfluxState, RootState>;

import { InfluxDB, Point } from "@influxdata/influxdb-client-browser";
import {
  InfluxConnectionCfg,
  ScannedAsset,
} from "@/sharedTSCode/types/ui-bebos-api";

const state: InfluxState = {
  writeApi: null,
};
const actions: ActionTree<InfluxState, RootState> = {
  async setupInflux(
    ctx: InfluxContext,
    params: { location: string; influx: InfluxConnectionCfg }
  ) {
    const writeApi = new InfluxDB({
      url: params.influx.url,
      token: params.influx.token,
    }).getWriteApi(params.influx.org, params.influx.bucket, "ns");
    const location = params.location;
    writeApi.useDefaultTags({ location });
    ctx.state.writeApi = writeApi;
  },

  async logToInflux(ctx: InfluxContext, influxMsg: InfluxMsg) {
    const point = influxMsg.point ?? new Point();
    point
      .measurement("term_ui")
      .tag("context", influxMsg.context)
      .tag("level", influxMsg.level ?? "debug")
      .stringField("msg", influxMsg.msg);

    const writeApi = ctx.state.writeApi;
    try {
      if (writeApi == null) throw new Error("Write Api not set up.");
      writeApi.writePoint(point);
      await writeApi.flush();
    } catch (e) {
      console.warn(`Failed to write ${point}: ${e}`);
    }
  },
};
const module: Module<InfluxState, RootState> = { state, actions };
export default module;

export function reduceAssetInfo(asset: ScannedAsset): string {
  return JSON.stringify({
    id: asset.id,
    name: asset.name,
    current_assign: asset.current_assign,
    assignedScore: asset.assignScore,
    assigned: asset.assigned,
  });
}
