<template>
  <v-row
    style="background: #545454; z-index: 2; border-radius: 25px"
    class="pa-8 ma-5"
  >
    <v-text-field
      height="20"
      style="display: flex; align-items: center"
      type="search"
      prepend-icon="mdi-magnify"
      clearable
      :label="labelText"
      full-width
      class="pa-0 ma-0"
      v-model="searchText"
      autofocus
    />

    <div
      :class="{
        'show-menu': showMenu,
        'over-menu': !showMenu,
      }"
    >
      <div
        style="
        bottom: 0
        z-index: 2;
        box-shadow: 0px -15px 40px rgba(0, 0, 0, 0.3);
        border-top: 2px solid rgba(255, 255, 255, 0.5);
      "
        class="simple-keyboard"
      />
    </div>
  </v-row>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";
import { wattroLayout, wattroDisplayKeys } from "./layout";

@Component({})
export default class Searchbar extends Vue {
  @Prop({ default: "Suche" }) labelText!: string;
  @Prop({ default: true }) showMenu!: boolean;
  public searchText = "";
  keyboard: Keyboard | null = null;
  mounted(): void {
    this.keyboard = new Keyboard("simple-keyboard", {
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      layout: wattroLayout.layout,
      display: wattroDisplayKeys,
    });
  }
  @Watch("searchText")
  private onSearchTextChange() {
    const k = this.getValidKeyboard();
    k.setInput(this.searchText);
    this.$emit("search-input", this.searchText);
  }

  onChange(input: string): void {
    this.searchText = input;
    this.$emit("search-input", this.searchText);
  }

  onKeyPress(button: string): void {
    if (button === "{shift}" || button === "{lock}") this.handleShift();
  }

  handleShift(): void {
    const k = this.getValidKeyboard();

    let currentLayout = k.options.layoutName;
    let shiftToggle = currentLayout === "default" ? "shift" : "default";
    k.setOptions({
      layoutName: shiftToggle,
    });
  }

  private getValidKeyboard(): Keyboard {
    const k = this.keyboard || undefined;
    if (k === undefined) throw new Error("init failed");
    return k;
  }
}
</script>

<style>
.show-menu {
  bottom: 7vh;
  left: 0;
  right: 0;
  position: fixed;
}

.over-menu {
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.v-input input {
  max-height: none !important;
}

.v-input .v-label,
.v-input input,
.v-input .v-icon {
  font-size: 40px !important;
}
.v-text-field--filled .v-label {
  display: flex !important;
  align-items: center !important;
}

.v-input .v-label {
  line-height: auto !important;
  height: auto !important;
  position: initial !important;
  overflow: visible !important;
}

.v-application--is-ltr .v-input__prepend-outer {
  margin-top: -5px !important;
  margin-right: 30px !important;
}
</style>

<style>
.hg-button:active {
  color: black;
  font-size: 45px;
}

.hg-theme-default {
  background-color: #020407;
  border-radius: 0;
  font-family: Futura T;
  font-size: 35px;
  padding: 10px;
}

.hg-theme-default .hg-button {
  background: rgba(255, 255, 255, 0.6);
  border-bottom: rgba(255, g255, 255, 0.9) 1px solid;
  border-radius: 10px;
  height: 80px;
}

.hg-theme-default .hg-button:active {
  transform: scale(1.1);
  transition-delay: 50ms;
}
</style>
