<template>
  <v-container class="pa-0" rounded-lg style="height: 100%; aspect-ratio: 1/1">
    <div
      class="d-flex flex-column"
      style="position: absolute; z-index: 3"
      @click="loadWarnings()"
    >
      <div v-if="displayFlags">
        <AssetFlag
          v-for="flag of activeFlags"
          :key="`${JSON.stringify(flag)}`"
          :flag="flag"
        />
      </div>
    </div>
    <v-app-bar
      flat
      class="pa-0 border-radius-top-15px"
      style="height: 10%; background: white; z-index: 2"
    >
      <v-spacer></v-spacer>
      <v-btn
        v-if="deselectable"
        color="grey"
        icon
        @click="
          $store.dispatch('setAssignedForAsset', {
            assigned: false,
            assetId: asset.id,
          })
        "
      >
        <v-icon style="font-size: 3em">mdi-close</v-icon>
      </v-btn>
    </v-app-bar>

    <div style="height: 70%; background: white" class="pa-2">
      <v-img
        :src="getAssetImage()"
        contain
        style="height: 100%; margin-top: -10px"
      />
    </div>

    <v-row
      class="pl-5 pr-5 ma-0 border-radius-bottom-15px"
      style="height: 20%; background: #05151b"
    >
      <v-col class="d-flex align-end pa-0" cols="12">
        <h3 style="font-size: 1.75em" class="d-block text-truncate">
          {{ asset.name }}
        </h3>
      </v-col>
      <v-col class="d-flex pa-0" cols="12"
        ><p class="text-h5 ma-0 d-block text-truncate">
          {{ asset.human_id }}
        </p></v-col
      >
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { Asset } from "@/sharedTSCode/types/nodeTypes";
import AssetFlag from "@/components/Tile/Flag.vue";

@Component({ components: { AssetFlag } })
export default class AssetTile extends Vue {
  @Prop() public asset!: Asset;
  @Prop({ default: true }) public deselectable!: boolean;
  @Prop({ default: true }) public displayFlags!: boolean;

  get activeFlags(): Array<keyof Asset["flags"]> {
    return Object.entries(this.asset.flags)
      .filter(([_, val]) => val)
      .map(([key, _]) => key as keyof Asset["flags"]);
  }

  public getAssetImage(): string {
    return this.asset.img["main"][0].picture.large;
  }

  public loadWarnings(): void {
    this.$store.dispatch("evaluateWarningsOnAsset", this.asset);
  }
}
</script>

<style scoped>
.border-radius-top-15px {
  border-top-right-radius: 15px !important;
  border-top-left-radius: 15px !important;
}

.border-radius-bottom-15px {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
</style>
