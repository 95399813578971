<template>
  <v-container fluid class="pa-0 mb-5 elevation-12">
    <v-row class="no-gutters d-flex flex-column white--text pa-10 pt-0 success">
      <!-- success -->
      <v-col cols="auto" class="d-flex">
        <v-col cols="2" class="d-flex pa-0">
          <v-icon size="80">mdi-check</v-icon>
        </v-col>
        <v-col class="d-flex align-center pa-0">
          <span class="text-h1">Buchung erfolgreich</span>
        </v-col>
      </v-col>

      <!-- info: go away -->
      <v-col class="d-flex text-h2">
        <v-col cols="2" class="d-flex">
          <v-icon size="50">mdi-hand-wave</v-icon>
        </v-col>
        <v-col class="d-flex align-center pa-0">
          <span>Bis später!</span>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component({ components: {} })
export default class HandoverInfo extends Vue {}
</script>
