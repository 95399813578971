import Vue, { nextTick } from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import { InfluxMsg, LogLevel, Snack } from "@/types/storeTypes";

import Home from "../views/Home.vue";
import Uebergabe from "../views/Uebergabe.vue";
import Init from "../views/Init.vue";
import AssetWarningVue from "../views/AssetWarningView.vue";
import { CancelReason } from "@/store/modules/handover";

const Suche = () => import(/* webpackChunkName: "lazy" */ "../views/Suche.vue");
const Hilfe = () => import(/* webpackChunkName: "lazy" */ "../views/Hilfe.vue");
const Analyze = () =>
  import(/* webpackChunkName: "lazy" */ "../views/Analyze.vue");
const Testing = () =>
  import(/* webpackChunkName: "dev" */ "../views/dev/Testing.vue");
const Locked = () =>
  import(/* webpackChunkName: "lazy" */ "../views/Locked.vue");

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/init",
    name: "Init",
    component: Init,
  },
  {
    path: "/suche",
    name: "Suche",
    component: Suche,
  },
  {
    path: "/hilfe",
    name: "Hilfe",
    component: Hilfe,
  },
  {
    path: "/",
    name: "Übergabe",
    component: Uebergabe,
  },
  {
    path: "/warning",
    name: "Warnung",
    component: AssetWarningVue,
  },
  {
    path: "/test",
    name: "Testing",
    component: Testing,
  },
  {
    path: "/analyze",
    name: "Analyze",
    component: Analyze,
  },
  {
    path: "/locked",
    name: "Locked",
    component: Locked,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const pathsThatPreserveHandover = ["/", "/warning"];

function getLogLevelOfRoute(route: Route): LogLevel {
  switch (route.fullPath) {
    case "/hilfe":
    case "/locked":
      return "warning";
    case "/suche":
      return "info";
    default:
      return "debug";
  }
}

router.beforeEach(
  async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
    const store = router.app.$store;
    /* check first render */
    if (store === undefined) {
      // will be injected after first complete render
      return next();
    }

    /* check initial node connection done */
    if (!store.getters.initialNodeConnectionDone) {
      if (to.fullPath === "/init") {
        return next();
      }
      console.warn(
        "Try to navigate without inital node connection. Forcing /init"
      );
      return next("/init");
    }

    /* check if terminal is locked */
    if (store.getters.isLocked && to.fullPath !== "/locked") {
      if (store.getters.isActive) {
        const locked: Snack = {
          type: "error",
          timed: true,
          msg: "Terminal gesperrt",
          actionList: [],
        };
        store.commit("pushSnack", locked);
      }
      if (from.fullPath === "/locked") {
        return;
      } else {
        return next("/locked");
      }
    }

    /* log navigation */
    const iMsg: InfluxMsg = {
      msg: `navigating to ${to.fullPath}`,
      context: "router",
      level: getLogLevelOfRoute(to),
    };
    store.dispatch("logToInflux", iMsg);

    nextTick(() => {
      Vue.prototype.$posthog.capture("$pageview", {
        $current_url: to.fullPath,
      });
    });

    /* cleanup */
    store.commit("cleanSnacks");
    if (from.fullPath === "/warning") {
      store.commit("clearAssetWarningList");
    }

    /* navigation with sideeffects */
    if (
      store.getters.isHandoverActive &&
      !pathsThatPreserveHandover.includes(to.path)
    ) {
      const cancelReason: CancelReason = "routingUI";
      store.dispatch("cancelHandover", { cancelReason });
      return next();
    }
    return next();
  }
);

export default router;
