import {
  BebosConnectionState,
  BebosStoreState as BebosState,
  RootState,
  SensorsCalibState,
} from "@/types/storeTypes";
import { ActionContext, ActionTree, Module } from "vuex";
import { BebosWs } from "@/utils/bebos_ws";
import { MsgToBebos, MsgToUI } from "@/sharedTSCode/types/ui-bebos-api";
import { bebosMessageActions } from "./bebosMessageActions";

export type BebosContext = ActionContext<BebosState, RootState>;

const state: BebosState = {
  ws: new BebosWs(),
};
const getters = {};

const mutations = {};

function setBebosState(ctx: BebosContext, val: BebosConnectionState) {
  ctx.commit("setBebosConnectionState", val, { root: true });
}
export function setSensState(ctx: BebosContext, val: SensorsCalibState) {
  ctx.commit("setSensorsCalibState", val, { root: true });
}

const actions: ActionTree<BebosState, RootState> = {
  async setupWs(ctx: BebosContext): Promise<void> {
    let stage = "pending";
    ctx.state.ws.setupWs({
      msgListener: (msg: MsgToUI) => ctx.dispatch("handleMsgToUI", msg),
      connectedCallback: () => {
        setBebosState(ctx, "connected");
        stage = "success";
      },
      errorCallback: () => {
        setBebosState(ctx, "notConnected");
        setSensState(ctx, "notConnected");
        const oldStage = stage;
        stage = "failed";
        if (oldStage === "pending" || oldStage === "failed") return;
        location.reload();
      },
    });

    while (stage == "pending") {
      console.debug("waiting for bebos connection");
      await new Promise((res) => setTimeout(res, 100));
    }
    if (stage == "failed") {
      return Promise.reject("Failed to connect to Bebos");
    }
  },
  async handleMsgToUI(ctx: BebosContext, msg: MsgToUI): Promise<void> {
    const action = bebosMessageActions.find(
      (a) => a.actionName === msg.action
    )?.action;
    if (action !== undefined) {
      return action({ ctx, payload: msg.payload });
    }
    console.debug("No Action implemented for msg", msg);
  },
  async sendToBebos(ctx: BebosContext, msg: MsgToBebos): Promise<void> {
    ctx.state.ws.send(msg);
  },
};

const module: Module<BebosState, RootState> = {
  state,
  getters,
  actions,
  mutations,
};

export default module;
