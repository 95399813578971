import {
  TerminalState,
  RootState,
  BebosConnectionState,
  SensorsCalibState,
  Snack,
  SoundName,
  FixableAsset,
} from "@/types/storeTypes";
import {
  ActionContext,
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from "vuex";

import {
  ConnectionInfoMsg,
  Interaction,
  MsgToBebos,
} from "@/sharedTSCode/types/ui-bebos-api";
import router from "@/router";
import * as Sentry from "@sentry/vue";
import { WattroNodeConnection } from "@/sharedTSCode/wattroNode/connect";
import { Store, Terminal } from "@/sharedTSCode/types/nodeTypes";
import { CancelReason } from "./handover";

type TerminalContext = ActionContext<TerminalState, RootState>;
const warningSound: SoundName = "warning";

const state: TerminalState = {
  locked: false,
  bebosConnectionState: "notConnected",
  sensorsCalibState: "notConnected",
  interaction: "noInteraction",
  bebosInfo: null,
  preferences: { force_key_card_login: false, nfc_enabled: true },
  nodeTerminal: undefined,
};
const getters: GetterTree<TerminalState, RootState> = {
  isActive(state: TerminalState, getters, rootState, rootGetters): boolean {
    return (
      state.interaction !== "noInteraction" || rootGetters.hasErrorsOrWarnings
    );
  },
  isReady(state: TerminalState): boolean {
    return (
      state.bebosConnectionState === "connected" &&
      state.sensorsCalibState !== "notConnected" &&
      state.sensorsCalibState.total == 100
    );
  },
  isFullyConnected(state: TerminalState): boolean {
    return (
      state.bebosConnectionState === "connected" &&
      state.sensorsCalibState !== "notConnected"
    );
  },
  calibPercentage(state: TerminalState): number {
    if (state.sensorsCalibState === "notConnected") {
      return -1;
    }
    return state.sensorsCalibState.total;
  },
  terminalName(state: TerminalState): string {
    if (state.bebosInfo == null) {
      return "";
    }
    return state.bebosInfo.terminal.name;
  },

  force_key_card_login(state: TerminalState): boolean {
    return state.preferences.force_key_card_login;
  },

  isLocked(state: TerminalState): boolean {
    return state.locked;
  },

  forceHomeStore(state: TerminalState): boolean {
    return state.nodeTerminal?.force_home_store ?? false;
  },
};

const mutations: MutationTree<TerminalState> = {
  setBebosConnectionState(
    state: TerminalState,
    newState: BebosConnectionState
  ) {
    state.bebosConnectionState = newState;
  },
  setConnectionInfo(
    state: TerminalState,
    newInfo: ConnectionInfoMsg["payload"]
  ) {
    state.bebosInfo = newInfo;
    Sentry.setTag("nodeUrl", newInfo.node.url);
    Sentry.setTag("terminalName", newInfo.terminal.name);
  },
  setSensorsCalibState(state: TerminalState, newState: SensorsCalibState) {
    state.sensorsCalibState = cleanSensorCalibState(newState);
  },
  _setInteraction(state: TerminalState, newState: Interaction) {
    state.interaction = newState;
  },
};

const actions: ActionTree<TerminalState, RootState> = {
  async setInteraction(
    ctx: TerminalContext,
    newInteraction: Interaction
  ): Promise<void> {
    const oldInteraction = ctx.state.interaction;
    ctx.commit("_setInteraction", newInteraction);

    if (newInteraction === "noInteraction") {
      reactToNoInteraction(ctx);
      return;
    }

    ctx.commit("makeSureHandoverStarted");

    if (oldInteraction === "noInteraction") {
      cancelGoingDirtyTimeout(ctx);
    }
    if (newInteraction === "near") {
      stopWarningSound(ctx);
    }
  },

  async getHandoverBtnConfig(_ctx: TerminalContext): Promise<{
    takeover: { text: string; icon: string };
    release: { text: string; icon: string };
    first: "takeover" | "release";
  }> {
    return defaultBtnConfig;
  },

  async loadTerminalPreferences(ctx: TerminalContext): Promise<void> {
    const con: WattroNodeConnection | null =
      ctx.rootGetters.tryToGetNodeConnection;
    if (!con) {
      console.error("'loadTerminalPreferences' called with invalid connection");
      return Promise.reject("Connection not set up.");
    }
    ctx.state.preferences.force_key_card_login = !(await con.getPreference(
      "menu_login_enabled"
    ));
    ctx.state.preferences.nfc_enabled = !!(await con.getPreference(
      "nfc_enabled"
    ));

    const cachedTerminal = ctx.state.bebosInfo?.terminal;
    if (cachedTerminal === undefined) {
      return Promise.reject("Terminal was not provided by bebos.");
    }
    ctx.state.nodeTerminal = await con.getById<Terminal>(
      "terminal",
      cachedTerminal.id
    );
    if (undefined === ctx.state.nodeTerminal) {
      ctx.state.nodeTerminal = cachedTerminal;
    }
    ctx.state.locked = ctx.state.nodeTerminal.locked;
    ctx.state.linkedStore = await con.getById<Store>(
      "store",
      ctx.state.nodeTerminal.store
    );
  },

  filterForWrongHome(
    ctx: TerminalContext,
    assetList: FixableAsset[]
  ): FixableAsset[] {
    /**
     * Filter scanned assets for those that have a home that is not the terminal's store
     */
    const thisStore = ctx.state.nodeTerminal?.store;
    if (!thisStore) return [];
    return assetList.filter((asset) => {
      if (asset.home_employee !== null) return true;
      if (asset.home_store === null) return false;
      return asset.home_store !== thisStore;
    });
  },

  async lockTerminal(ctx: TerminalContext) {
    ctx.state.locked = true;
    goTo("/locked");
    await setLockOnNodeTerminal(ctx);
  },

  async unlockTerminal(ctx: TerminalContext) {
    ctx.state.locked = false;
    displayRoot();
    await setLockOnNodeTerminal(ctx);
  },
};

async function setLockOnNodeTerminal(ctx: TerminalContext): Promise<Terminal> {
  const con: WattroNodeConnection | null =
    ctx.rootGetters.tryToGetNodeConnection;
  if (!con) {
    console.error("'lockTerminal' called with invalid connection");
    return Promise.reject("Connection not set up.");
  }
  const terminal = ctx.state.nodeTerminal;
  if (terminal === undefined) {
    return Promise.reject("No terminal data.");
  }
  terminal.locked = ctx.state.locked;
  return con.update<Terminal>("terminal", terminal);
}

const defaultBtnConfig = {
  takeover: { text: "mitnehmen", icon: "mdi-account" },
  release: { text: "abgeben", icon: "mdi-home" },
  first: "takeover" as const,
};

function reactToNoInteraction(ctx: TerminalContext) {
  const interruptingHandover =
    ctx.rootGetters.isHandoverActive &&
    ctx.rootGetters.hasHandoverAssignedAssets;

  const isWarningActive = ctx.rootGetters.hasErrorsOrWarnings;

  if (interruptingHandover) {
    ctx.commit("hideSuggestions");
    startWarningSound(ctx);
    if (!isWarningActive) {
      showInterruptedHandoverAlert(ctx);
    }
    setGoingDirtyTimeout(ctx);
    displayRoot();
  } else {
    setToIdle(ctx);
  }
}

function displayRoot() {
  goTo("/");
}

function goTo(path: string) {
  const curPath = router.currentRoute.path;
  if (curPath !== path) {
    router.push(path);
  }
}

function showInterruptedHandoverAlert(ctx: TerminalContext) {
  ctx.commit("pushSnack", getActiveHandoverWarning(ctx));
}

function setToIdle(ctx: TerminalContext) {
  stopWarningSound(ctx);
  ctx.commit("_setInteraction", "noInteraction");
  ctx.dispatch("resetHandover");
  ctx.commit("logout");
  ctx.commit("resetSnacks");
  displayRoot();
}

function stopWarningSound(ctx: TerminalContext) {
  ctx.dispatch("stopPlay");
}

function startWarningSound(ctx: TerminalContext) {
  ctx.dispatch("startPlay", warningSound);
}

function setGoingDirtyTimeout(ctx: TerminalContext) {
  ctx.state._goingDirtyTimeout = setTimeout(() => {
    const cancelReason: CancelReason = "goingAway";
    ctx.dispatch("cancelHandover", { cancelReason });
    sendGoingDirtyToBebos(ctx);
    setToIdle(ctx);
  }, 10 * 1000);
}

function cancelGoingDirtyTimeout(ctx: TerminalContext) {
  if (ctx.state._goingDirtyTimeout !== undefined) {
    clearTimeout(ctx.state._goingDirtyTimeout);
  }
}

async function sendGoingDirtyToBebos(ctx: TerminalContext) {
  const goingDirtyMsg: MsgToBebos = {
    action: "goneInDirtyState",
    payload: {
      assetList: ctx.getters.handoverAssetList,
    },
  };
  await ctx.dispatch("sendToBebos", goingDirtyMsg);
}

function getActiveHandoverWarning(_ctx: TerminalContext): Snack {
  return {
    type: "error",
    timed: false,
    msg: "Übergabe nicht abgeschlossen",
    actionList: [],
  };
}

function cleanSensorCalibState(state: SensorsCalibState): SensorsCalibState {
  if (state === "notConnected" || state === undefined || state === null) {
    return "notConnected";
  }
  return state;
}

const module: Module<TerminalState, RootState> = {
  state,
  getters,
  actions,
  mutations,
};

export default module;
