<template>
  <v-card class="pa-5 mt-0">
    <v-row>
      <v-col v-if="showFullHelp" cols="6">
        <v-btn
          light
          color="primary"
          block
          large
          @click="toggleHandoverSuggestionHint()"
          class="black--text"
        >
          <span>Hilfe</span>
          <v-spacer></v-spacer>
          <v-icon right x-large>mdi-close</v-icon>
        </v-btn>
      </v-col>
      <v-col v-else cols="6">
        <v-btn outlined block large @click="toggleHandoverSuggestionHint()">
          Hilfe
        </v-btn>
      </v-col>
      <v-col cols="0" v-if="showFullHelp"> </v-col>
      <v-col cols="6" v-else>
        <v-btn block dark large @click="hideSuggestions()"> zurück </v-btn>
      </v-col>
    </v-row>

    <v-row style="overflow: auto" v-if="showFullHelp" class="mt-10">
      <v-col cols="8">
        <v-card-title class="text-h4">Wattro-Tag überprüfen</v-card-title>
        <v-card-text class="text-h5">
          <p>Ist ein 'Wattro-RFID-Tag' am Gerät angebracht?</p>
          <p>Ist der 'Wattro-RFID-Tag' beschädigt?</p>
        </v-card-text>
      </v-col>
      <v-col cols="4">
        <v-img src="wattro-tag.png" contain style="max-height: 20em" />
      </v-col>

      <v-col cols="8">
        <v-card-title class="text-h4">Lesebereich des Terminals </v-card-title>
        <v-card-text class="text-h5">
          <p>
            Geräte vor die Markierung am Terminal(<v-icon large
              >mdi-access-point</v-icon
            >) halten.
          </p>
          <p>'Wattro Tag' nicht abschirmen.</p>
        </v-card-text>
      </v-col>
      <v-col cols="4">
        <v-img
          src="WattroTerminal_Drawing.png"
          contain
          style="max-height: 25em"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import { mapState, mapMutations } from "vuex";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
  computed: {
    ...mapState({
      showFullHelp: (state: any) => state.handover.suggestion.hint,
    }),
  },
  methods: {
    ...mapMutations(["toggleHandoverSuggestionHint", "hideSuggestions"]),
  },
})
export default class HandoverHilfe extends Vue {
  public showFullHelp!: boolean;
}
</script>
