<!-- location quick-info -->
<template>
  <div class="row d-flex align-center my-2 flex-row">
    <!-- assignee quick-info -->
    <div v-if="!addable" class="d-flex align-center col-auto pa-0">
      <!-- avatar - unknown -->
      <div v-if="!asset.current_assign || asignee === null" class="avatar-wrap">
        <v-avatar size="70" color="grey">
          <v-icon x-large>mdi-map-marker-question-outline</v-icon>
        </v-avatar>
        <div id="connector--unknown"></div>
      </div>
      <!-- avatar - store -->
      <div
        v-else-if="asset.current_assign.type === 'Store'"
        class="avatar-wrap"
      >
        <v-avatar size="70" color="rgb(23, 197, 75)">
          <v-icon x-large>mdi-home-map-marker</v-icon>
        </v-avatar>
        <div id="connector--store"></div>
      </div>
      <!-- Avatar - Employee -->
      <div
        v-else-if="asset.current_assign.type === 'Employee'"
        class="avatar-wrap"
      >
        <EmployeeAvatar :employee="asignee" size="70" />
        <div id="connector--avatar"></div>
      </div>
    </div>
    <!-- location quick-info end -->

    <div class="pa-0 col">
      <div class="row ma-0 white--text">
        <!-- asset image -->
        <div
          style="background-color: white; width: 120px"
          class="pa-1"
          color="white"
        >
          <v-img contain :src="getAssetImage()"></v-img>
        </div>
        <!-- asset quick-info -->
        <div
          style="background-color: #05151b"
          class="d-flex flex-row col text-truncate"
        >
          <!-- asset name -->
          <div class="col-4 d-flex justify-center flex-column text-truncate">
            <span class="grey--text text-h6"> {{ asset.human_id }}</span>
            <span
              style="font-weight: bold"
              class="text-h5 pa-0 d-block text-truncate"
              >{{ asset.name }}</span
            >
          </div>
          <!-- assignee -->
          <div class="col-4 d-flex justify-center flex-column text-truncate">
            <span class="grey--text text-h6">zugeordnet</span>
            <span class="text-h5 d-block text-truncate">
              <span v-if="asignee === null"> <em>unbekannt</em> </span>
              <span v-else>{{ asignee.name }}</span>
            </span>
          </div>
          <!-- assigned since -->
          <div
            class="col-4 d-flex justify-center flex-column align-end text-truncate"
          >
            <span class="grey--text text-h6 d-block text-truncate">
              seit {{ absoluteTime }}
            </span>
            <span class="text-h5 d-block text-truncate">
              {{ relativeTime }}
            </span>
          </div>
        </div>
        <!-- if addable -->
        <div class="d-flex align-center">
          <div v-if="addable" id="connector--small"></div>
          <v-btn
            width="150"
            v-if="addable && !asset.assigned"
            elevation="3"
            class="rounded-lg"
            @click="addThis()"
            color="yellow"
          >
            <div style="display: flex; flex-direction: column">
              <v-icon>mdi-plus</v-icon>
              <span class="text-caption">hinzufügen</span>
            </div>
          </v-btn>
          <v-btn
            width="150"
            v-if="addable && asset.assigned"
            color="green"
            @click="removeThis()"
          >
            <div style="display: flex; flex-direction: column">
              <v-icon color="white">mdi-check</v-icon>
              <span class="text-caption white--text">hinzugefügt</span>
            </div>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { Asset, Employee, Store } from "src/sharedTSCode/types/nodeTypes";
import EmployeeAvatar from "@/components/Employee/Avatar.vue";

@Component({
  components: { EmployeeAvatar },
})
export default class AssetListItem extends Vue {
  @Prop() asset!: Asset;
  @Prop() public addable!: boolean;
  @Prop() public recentlyAdded!: boolean;
  public asignee: Employee | Store | null = null;

  async mounted(): Promise<void> {
    const current_assign = this.asset.current_assign;
    if (current_assign === undefined || current_assign === null) return;
    if (current_assign.type === "Employee") {
      this.asignee = await this.$store.dispatch(
        "getEmployee",
        current_assign.id
      );
    } else if (current_assign.type === "Store") {
      this.asignee = await this.$store.dispatch("getStore", current_assign.id);
    }
  }

  public addThis(): void {
    this.$emit("added");
    this.$store.dispatch("setAssignedForAsset", {
      assigned: true,
      assetId: this.asset.id,
    });
  }

  public removeThis(): void {
    this.$store.dispatch("setAssignedForAsset", {
      assigned: false,
      assetId: this.asset.id,
    });
  }

  get assignTs(): number | undefined {
    if (!this.asset.current_assign) return undefined;
    return Date.parse(this.asset.current_assign.last_assign_ts);
  }

  get relativeTime(): string {
    if (this.assignTs === undefined) return "(unbekannt)";
    let dtf = new Intl.RelativeTimeFormat("de", { numeric: "auto" });
    return dtf.format(
      Math.ceil(
        (new Date(this.assignTs).valueOf() - Date.now()) / (1000 * 60 * 60 * 24)
      ),
      "day"
    );
  }
  get absoluteTime(): string {
    if (this.assignTs === undefined) return "(unbekannt)";
    return new Date(this.assignTs).toLocaleDateString("de-DE", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  public getAssetImage(): string {
    return this.asset.img["main"][0].picture.medium;
  }
}
</script>

<style scoped>
.v-btn {
  height: 110px !important;
}

.v-btn {
  color: #05151b;
}
#connector--avatar {
  background-color: #05151b;
}
#connector--store {
  background-color: rgb(23, 197, 75);
}
#connector--unknown {
  background-color: #9e9e9e;
}

#connector--store,
#connector--avatar,
#connector--unknown {
  height: 4px !important;
  width: 10px !important;
}

#connector--small {
  height: 80px;
  background: black;
  width: 5px;
}
.avatar-wrap {
  align-items: center;
  display: inline-flex;
}
</style>
