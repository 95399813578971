<template>
  <div
    style="background: #e6e6e6; color: #020407; flex-flow: column; height: 100%"
    class="d-flex pa-5"
  >
    <div v-if="showHandoverDone">
      <HandoverInfo />
    </div>

    <div v-if="isLoggedIn" class="pa-5 pl-0 pt-0">
      <h1>Mir aktuell zugewiesen</h1>
    </div>

    <div
      v-else-if="!showHandoverDone"
      cols="auto"
      class="pa-5 pl-0 pt-0"
      @click="$store.dispatch('showEmployeeLoginDialog')"
    >
      <h1>Für die Einsicht in Dein Inventar bitte anmelden.</h1>
    </div>
    <div class="flex-grow-1" style="background: #cdcdcd; overflow: auto">
      <EmployeeInventory />
    </div>
    <div class="d-flex justify-end align-center pt-5" style="min-height: 250px">
      <!-- next handover -->
      <v-col>
        <v-btn
          height="200"
          x-large
          block
          class="pa-10"
          color="#ffe22b"
          light
          to="/"
        >
          <div class="d-flex flex-column align-center">
            <v-icon>$scanIcon</v-icon>
            <span>Scan erneut starten</span>
          </div>
        </v-btn>
      </v-col>
      <!-- change employee -->
      <v-col cols="6" v-if="!showHandoverDone" class="pa-0">
        <v-btn
          v-if="isLoggedIn"
          class="pa-10 ma-0"
          height="200"
          x-large
          block
          color="#020407"
          @click="$store.commit('logout')"
          light
          outlined
        >
          <div class="d-flex flex-column">
            <v-icon x-large>mdi-logout</v-icon>
            <span class="mt-2">abmelden</span>
          </div>
        </v-btn>
        <v-btn
          v-else
          height="200"
          x-large
          block
          class="pa-10"
          color="#020407"
          @click="$store.dispatch('showEmployeeLoginDialog')"
        >
          <div class="d-flex flex-column">
            <v-icon x-large>mdi-login</v-icon>
            <span class="mt-2">anmelden</span>
          </div>
        </v-btn>
        <EmployeeLoginDialog />
      </v-col>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { mapGetters, mapState } from "vuex";

import EmployeeInventory from "@/components/Employee/Inventory.vue";
import EmployeeLoginDialog from "@/components/Employee/LoginDialog.vue";
import HandoverInfo from "@/components/Message/ProcessInfo.vue";

@Component({
  components: { EmployeeInventory, EmployeeLoginDialog, HandoverInfo },
  computed: {
    ...mapState({
      employee: (store: any) => store.employee.employee,
    }),
    ...mapGetters(["isLoggedIn", "justFinishedHandover"]),
  },
})
export default class Home extends Vue {
  private justFinishedHandover!: boolean;
  public showHandoverDone: boolean = false;
  mounted(): void {
    this.showHandoverDone = this.justFinishedHandover;
  }
  updated(): void {
    this.showHandoverDone = this.justFinishedHandover;
  }
}
</script>
