<template>
  <v-dialog
    v-model="wrongHomeShowError"
    overlay-color="black"
    overlay-opacity=".85"
    persistent
    transition="fade-transition"
  >
    <v-card :color="cardColor" style="overflow: hidden">
      <v-row class="ma-0">
        <v-col class="pa-3">
          <v-card-title
            class="text-h2 pa-0 ps-5 py-3"
            style="word-break: break-word"
          >
            {{ assetHomeMessage }}
          </v-card-title>
        </v-col>
        <v-col cols="auto" class="pa-3">
          <v-btn
            fab
            color="darkBlue"
            dark
            class="my-3"
            elevation="0"
            @click="closeWarningDialog"
            ><v-icon x-large>mdi-close</v-icon></v-btn
          >
        </v-col>
      </v-row>
      <div
        style="background-color: rgba(0, 0, 0, 0.25); width: 100% !important"
      >
        <v-row class="pa-5 ma-0 flex-row">
          <v-col
            cols="6"
            class="tile-grid pa-2"
            v-for="asset in assetListCurrentPage"
            :key="asset.id"
          >
            <WrongHomeAssetTile :asset="asset" />
          </v-col>
        </v-row>
        <!-- pagination start (max-length: 4 assets) -->
        <v-container class="max-width">
          <v-pagination
            v-model="page"
            dark
            :length="getPageCount()"
            :total-visible="4"
            v-show="getPageCount() > 1"
          />
        </v-container>
        <!-- pagination end -->
      </div>
      <v-row class="pa-5">
        <v-col v-if="!forceHomeStore">
          <v-btn x-large block class="text-h3 pa-10" @click="acceptWrongHome()">
            Trotzdem abgeben
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            x-large
            block
            class="text-h3 pa-10"
            @click="unselectAssetsWithWrongHome()"
          >
            {{ deselectMessage }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import { Asset } from "@/sharedTSCode/types/nodeTypes";
import { Component, Vue, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import WrongHomeAssetTile from "@/components/Tile/WrongHome.vue";
@Component({
  components: { WrongHomeAssetTile },
  computed: {
    ...mapGetters(["wrongHomeAssets", "wrongHomeShowError", "forceHomeStore"]),
  },
})
export default class WrongHomeDialog extends Vue {
  public wrongHomeAssets!: Asset[];
  public wrongHomeShowError!: boolean;
  public forceHomeStore!: boolean;
  public page = 1;
  public itemsPerPage = 4;
  public assetListCurrentPage: Asset[] = [];

  //card-color
  get cardColor() {
    return this.forceHomeStore ? "error" : "warning";
  }

  // paginator

  @Watch("page")
  @Watch("wrongHomeAssets", {
    immediate: true,
    deep: true,
  })
  setAssetListCurrentPage(): void {
    this.assetListCurrentPage = this.wrongHomeAssets.slice(
      (this.page - 1) * this.itemsPerPage,
      this.page * this.itemsPerPage
    );
  }

  getPageCount(): number {
    return Math.ceil(this.wrongHomeAssets.length / this.itemsPerPage);
  }

  // pluralization
  get assetHomeMessage(): string {
    const pluralize = this.wrongHomeAssets.length > 1;
    let msg = "";
    if (pluralize) {
      msg += "Folgende Geräte gehören nicht an diesen Lagerort.";
    } else {
      msg += "Dieses Gerät gehört nicht an diesen Lagerort.";
    }
    if (this.forceHomeStore) {
      msg += " Eine Rückgabe an diesem Terminal ist nicht möglich.";
    }
    return msg;
  }
  get deselectMessage(): string {
    const assetCount = this.wrongHomeAssets.length;
    return assetCount > 1 ? `Geräte abwählen` : `Gerät abwählen`;
  }

  public async acceptWrongHome(): Promise<void> {
    this.$store.commit("acceptWrongHome");
  }

  public async unselectAssetsWithWrongHome(): Promise<void> {
    await this.$store.dispatch("unassignAssetsWithWrongHome");
    this.$store.commit("acceptWrongHome", {
      doAccept: false,
    });
  }

  public async closeWarningDialog(): Promise<void> {
    this.$store.commit("acceptWrongHome", {
      doAccept: false,
    });
  }
}
</script>
<style scoped>
.tile-grid {
  height: calc(100% / 3) !important;
  width: 50% !important;
}

.v-dialog__content {
  padding-inline: 2vw;
}
</style>
