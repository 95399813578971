<template>
  <svg
    id="terminal-nfc-icon"
    data-name="terminal-nfc-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 166.43 68.79"
  >
    <g>
      <g id="Icon_material-lock-open" data-name="Icon material-lock-open">
        <path
          class="cls-1"
          d="M83.22,43.3c1.79,0,3.24-1.45,3.24-3.24s-1.45-3.24-3.24-3.24-3.24,1.45-3.24,3.24c0,1.79,1.45,3.23,3.24,3.24ZM92.93,28.73h-1.62v-3.24c0-4.47-3.63-8.1-8.1-8.1s-8.1,3.63-8.1,8.1h3.08c0-2.77,2.25-5.02,5.02-5.02s5.02,2.25,5.02,5.02v3.24h-14.74c-1.79,0-3.23,1.45-3.24,3.24v16.19c0,1.79,1.45,3.23,3.24,3.24h19.43c1.79,0,3.23-1.45,3.24-3.24v-16.19c0-1.79-1.45-3.23-3.24-3.24ZM92.93,48.16h-19.43v-16.19h19.43v16.19Z"
        />
      </g>
      <path
        class="cls-1"
        d="M83.22,68.79c-18.96,0-34.39-15.43-34.39-34.39S64.25,0,83.22,0s34.39,15.43,34.39,34.39-15.43,34.39-34.39,34.39ZM83.22,3.2c-17.2,0-31.19,13.99-31.19,31.19s13.99,31.19,31.19,31.19,31.19-13.99,31.19-31.19S100.42,3.2,83.22,3.2Z"
      />
    </g>
    <path
      class="cls-1"
      d="M0,33.74c.44-1.05,1.03-1.97,2.22-2.24.44-.1.9-.12,1.35-.12,11.19,0,15.67,0,26.86,0,.26,0,.51,0,.94,0-.24-.27-.38-.44-.54-.59-1.16-1.17-2.35-2.32-3.47-3.52-.86-.93-.98-2.22-.41-3.33.56-1.1,1.72-1.82,2.94-1.6.68.12,1.44.45,1.93.93,3.05,2.96,6.05,5.98,9.03,9,1.22,1.24,1.22,3.03,0,4.27-2.99,3.02-5.99,6.03-9.02,9.02-1.3,1.29-3.17,1.28-4.37.05-1.2-1.23-1.15-3.04.14-4.37,1.06-1.09,2.14-2.15,3.22-3.22.15-.15.29-.31.55-.59-.4,0-.64,0-.89,0-11.08,0-15.45-.03-26.53.03-1.91,0-3.29-.52-3.97-2.39v-1.3Z"
    />
    <path
      class="cls-1"
      d="M166.43,35.05c-.44,1.05-1.03,1.97-2.22,2.24-.44.1-.9.12-1.35.12-11.19,0-15.67,0-26.86,0-.26,0-.51,0-.94,0,.24.27.38.44.54.59,1.16,1.17,2.35,2.32,3.47,3.52.86.93.98,2.22.41,3.33-.56,1.1-1.72,1.82-2.94,1.6-.68-.12-1.44-.45-1.93-.93-3.05-2.96-6.05-5.98-9.03-9-1.22-1.24-1.22-3.03,0-4.27,2.99-3.02,5.99-6.03,9.02-9.02,1.3-1.29,3.17-1.28,4.37-.05,1.2,1.23,1.15,3.04-.14,4.37-1.06,1.09-2.14,2.15-3.22,3.22-.15.15-.29.31-.55.59.4,0,.64,0,.89,0,11.08,0,15.45.03,26.53-.03,1.91,0,3.29.52,3.97,2.39v1.3Z"
    />
  </svg>
</template>

<script>
export default {
  name: "NFCIcon",
};
</script>

<style scoped>
.cls-1 {
  fill: white;
  stroke-width: 0px;
}
.theme--light .cls-1 {
  fill: #000;
}

.v-bottom-navigation .cls-1 {
  fill: rgba(255, 255, 255, 0.7);
}

.v-bottom-navigation .v-btn--active .cls-1 {
  fill: #ffe22b;
}

.v-bottom-navigation #wattro-scan-icon {
  width: 45px;
  height: 45px;
}
</style>
