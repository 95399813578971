<template>
  <v-system-bar style="z-index: 4" class="pl-6 pr-6">
    <v-img src="wattro-logo.png" contain max-width="80px" class="mr-5"> </v-img>
    <span>Asset Tracking System</span>
    <v-spacer />
    <div
      class="pl-2 pr-2 mr-5"
      :class="{ 'highlight-text': employeeLoginEvent }"
    >
      <span>{{ employee.name }} </span>
    </div>

    <div :class="{ 'highlight-avatar': employeeLoginEvent }" class="mr-4">
      <EmployeeAvatar :employee="employee" v-if="isLoggedIn" size="45" />
    </div>

    <v-icon
      large
      class="mr-4"
      :color="connectionState === 'failed' ? 'error' : 'grey'"
      @click="offerReload()"
    >
      {{ nodeConIcon }}
    </v-icon>
    <v-icon large :color="accessColor" @click="showAnalyze">
      {{ accessIcon }}
    </v-icon>
  </v-system-bar>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { mapGetters, mapState } from "vuex";
import { Employee } from "@/sharedTSCode/types/nodeTypes";
import EmployeeAvatar from "@/components/Employee/Avatar.vue";
import { NodeConState, Snack } from "@/types/storeTypes";

@Component({
  components: { EmployeeAvatar },
  computed: {
    ...mapGetters(["isReady", "isFullyConnected", "isHandoverActive"]),
    ...mapState({
      employee: (store: any) => store.employee.employee,
      employeeLoginEvent: (store: any) => store.employee.loginEvent.flag,
      connectionState: (store: any) => store.nodeCon.connectionState,
    }),
    ...mapGetters(["isLoggedIn"]),
  },
})
export default class Header extends Vue {
  public employee!: Employee;
  public connectionState!: NodeConState["connectionState"];
  public employeeLoginEvent!: boolean;

  public isReady!: boolean;
  public isFullyConnected!: boolean;
  public isHandoverActive!: boolean;

  get accessColor(): string {
    if (!this.isFullyConnected) {
      return "error";
    }
    if (!this.isReady) {
      return "warning";
    }
    if (this.isHandoverActive) {
      return "success";
    }
    return "";
  }

  get nodeConIcon(): string {
    switch (this.connectionState) {
      case "connecting":
        return "mdi-web-sync";
      case "connected":
        return "mdi-web-check";
      case "failed":
        return "mdi-web-remove";
      default:
        return "mdi-web-box";
    }
  }

  get accessIcon(): string {
    if (!this.isFullyConnected) {
      return "mdi-access-point-remove";
    }
    return "mdi-access-point";
  }

  public offerReload(): void {
    const offerReloadSnack: Snack = {
      type: this.connectionState !== "connected" ? "error" : "info",
      timed: false,
      ttl: 1,
      msg: "Oberfläche neu laden?",
      description: `Wattro Node: '${this.connectionState}'`,
      actionList: [
        {
          msg: "Neu laden.",
          icon: "mdi-web-refresh",
          action: () => {
            location.reload();
          },
        },
      ],
    };
    this.$store.commit("pushSnack", offerReloadSnack);
  }

  public showAnalyze(): void {
    if (this.$route.path !== "/analyze") {
      this.$router.push("/analyze");
    }
  }
}
</script>

<style>
.v-system-bar {
  background-color: transparent !important;
}
.v-system-bar p {
  font-size: 1.5em !important;
}

.highlight-text {
  background: #ffe22b;
  color: black;
}

.highlight-avatar {
  box-shadow: 0px 0px 0px 4px #ffe22b;
  border-radius: 50%;
}
</style>
