import router from "@/router";
import { Asset, AssetLog, Employee } from "@/sharedTSCode/types/nodeTypes";
import {
  HandoverInfoMsg,
  MsgToBebos,
  UIRequestsRescanMsg,
  ScannedAsset,
  ScannedItem,
} from "@/sharedTSCode/types/ui-bebos-api";
import {
  FixableAsset,
  HandoverProcess,
  HandoverState,
  InfluxMsg,
  RootState,
  Snack,
  SoundName,
} from "@/types/storeTypes";
import { TimeoutFlag } from "@/utils/extendableTimer";
import {
  ActionContext,
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from "vuex";

import suggestion from "./handoverSuggestions";
import { Point } from "@influxdata/influxdb-client-browser";
import {
  clearAssetList,
  archiveAssetList,
  updateAssetListWithScan,
  setAndFixAssignedForAsset,
  restoreAssetListFromHistory,
  triggerWarningIfNeeded,
} from "./_manipulateHandoverAssetList";
import { WattroNodeConnection } from "@/sharedTSCode/wattroNode/connect";
import { reduceAssetInfo } from "./influx";
import { userAbortedLoginError } from "@/store/modules/employee";
import { wait_ms } from "@/store/helpers";
import { nextTick } from "vue";

export type HandoverContext = ActionContext<HandoverState, RootState>;
export type CancelReason = "explicitUI" | "routingUI" | "goingAway";

const handoverPath = "/";
const successSound: SoundName = "ok";

const state: HandoverState = {
  assetList: [],
  assetsLoading: [],
  _assetListHistory: [],
  process: HandoverProcess.notStarted,
  _justFinished: new TimeoutFlag(500, false),
  currentChange: new TimeoutFlag(500, false),
  forceAssetAssignedFalse: 0,
  wrongHome: {
    assets: [],
    showError: false,
    accept: null,
  },
};

const getters: GetterTree<HandoverState, RootState> = {
  assignedAssetList(st: HandoverState): ScannedAsset[] {
    return st.assetList.filter((a) => a.assigned);
  },
  wrongHomeAssets(st: HandoverState): FixableAsset[] {
    return st.wrongHome.assets;
  },
  wrongHomeShowError(st: HandoverState): boolean {
    return st.wrongHome.showError;
  },
  unassignedAssetList(st: HandoverState): ScannedAsset[] {
    return st.assetList.filter((a) => !a.assigned);
  },
  assetSkelCount(st: HandoverState): number {
    return st.assetsLoading.filter((a) => a.loading).length;
  },

  isHandoverActive(st: HandoverState): boolean {
    return st.process === HandoverProcess.active;
  },
  isHandoverPossible(st: HandoverState, getters: any): boolean {
    return !st.currentChange.flag && getters.hasHandoverAssignedAssets;
  },
  hasHandoverAssignedAssets(st: HandoverState, getters: any): boolean {
    return getters.assignedAssetList.length + getters.assetSkelCount > 0;
  },

  handoverAssetList(st: HandoverState): Asset[] {
    return st.assetList;
  },

  justFinishedHandover(st: HandoverState): boolean {
    return st._justFinished.flag;
  },
};
const mutations: MutationTree<HandoverState> = {
  restoreHandover(st: HandoverState) {
    st.process = HandoverProcess.active;
    restoreAssetListFromHistory(st);
    displayHandoverIfNeeded();
  },
  makeSureHandoverStarted(st: HandoverState) {
    if (st.process === HandoverProcess.notStarted) {
      st.process = HandoverProcess.active;
    }
  },
  forceAssetActiveFalseTwice(st: HandoverState) {
    st.forceAssetAssignedFalse = 2;
  },
  acceptWrongHome(st: HandoverState, payload: { doAccept: boolean }) {
    st.wrongHome.accept = payload?.doAccept ?? true;
  },
};

const actions: ActionTree<HandoverState, RootState> = {
  enterHandover(ctx: HandoverContext) {
    if (ctx.state.process === HandoverProcess.notStarted) return;
    if (ctx.state.process === HandoverProcess.active) return;
    ctx.state.process = HandoverProcess.active;
    requestRescan(ctx);
    displayHandoverIfNeeded();
  },

  resetHandover(ctx: HandoverContext): void {
    clearAssetList(ctx);
    ctx.commit("resetHandoverSuggestion");
    ctx.commit("hideEmployeeLoginDialog");
    ctx.state.process = HandoverProcess.notStarted;
    ctx.state.wrongHome = {
      assets: [],
      showError: false,
      accept: null,
    };
  },

  async updateAssetList(
    ctx: HandoverContext,
    scannedItemList: ScannedItem[]
  ): Promise<void> {
    if (ctx.getters.isLocked) return;
    return updateAssetListWithScan(ctx, scannedItemList);
  },

  async evaluateWarningsOnAsset(
    ctx: HandoverContext,
    asset: Asset
  ): Promise<void> {
    return triggerWarningIfNeeded(ctx, asset);
  },

  async getAssignedAssetsWithWrongHome(
    ctx: HandoverContext
  ): Promise<FixableAsset[]> {
    return await ctx.dispatch(
      "filterForWrongHome",
      ctx.getters.assignedAssetList
    );
  },

  async unassignAssetsWithWrongHome(ctx: HandoverContext): Promise<void> {
    for (const asset of ctx.state.wrongHome.assets) {
      await ctx.dispatch("setAssignedForAsset", {
        assigned: false,
        assetId: asset.id,
      });
    }
  },

  async commitHandover(
    ctx: HandoverContext,
    handoverType: "release" | "takeOver"
  ): Promise<void> {
    ctx.dispatch("logToInflux", {
      msg: "triggeredHandover",
      context: "store.handover",
    });

    const assignedAssetList: ScannedAsset[] = ctx.getters.assignedAssetList;
    const /* but not frozen */ handoverInfo: HandoverInfoMsg = {
        toStore: true,
        assignedAssetList,
        unassignedAssetList: ctx.getters.unassignedAssetList,
      };
    let newProcessState = HandoverProcess.releaseDone;

    if (handoverType === "takeOver") {
      handoverInfo.toStore = false;
      try {
        await ctx.dispatch("makeSureIsLoggedIn");
      } catch (didNotLogInErr) {
        if (didNotLogInErr == userAbortedLoginError) {
          console.log(didNotLogInErr);
        } else {
          console.error("Failed to log in", didNotLogInErr);
        }
        return;
      }
      const emp = ctx.rootGetters["currentEmployee"] as Employee;
      handoverInfo.toEmployee = emp;
      newProcessState = HandoverProcess.takeoverDone;
    } else {
      if (!(await couldManageWrongHome(ctx))) {
        return;
      }
    }
    await sendCommitInfo();

    cleanUpHandover();

    return;

    // helper functions
    async function sendCommitInfo() {
      // report handover
      const reducedInfo = {
        ...handoverInfo,
        assignedAssetList: handoverInfo.assignedAssetList.map(reduceAssetInfo),
        unassignedAssetList:
          handoverInfo.unassignedAssetList.map(reduceAssetInfo),
      };
      ctx.dispatch("logToInflux", {
        msg: "committedHandover",
        context: "store.handover",
        point: new Point()
          .intField("asset_count", assignedAssetList.length)
          .booleanField("releaseToStore", handoverInfo.toStore)
          .stringField("handoverInfo", JSON.stringify(reducedInfo)),
      });

      // report explicit canceled during handover
      reportCanceled(
        ctx,
        ctx.state.assetList.filter((a) => !a.assigned && a.fixedAssigned),
        "explicitUI"
      );

      const bebosMsg: MsgToBebos = {
        action: "commitHandover",
        payload: handoverInfo,
      };
      await ctx.dispatch("sendToBebos", bebosMsg);
    }

    function cleanUpHandover() {
      state.process = newProcessState;
      state._justFinished.set();

      archiveAssetList(ctx);
      ctx.dispatch("playOnce", successSound);
      displayDone();
      ctx.dispatch("forceRefreshEmployeeList");
    }
  },

  async setAssignedForAsset(
    ctx: HandoverContext,
    payload: { assigned: boolean; assetId: number }
  ) {
    setAndFixAssignedForAsset(ctx, payload.assigned, payload.assetId);
  },

  async cancelHandover(
    ctx: HandoverContext,
    payload: { cancelReason: CancelReason }
  ) {
    ctx.state.process = HandoverProcess.aborted;
    /* await */
    reportCanceled(
      ctx,
      ctx.state.assetList.filter(
        (a) => a.assigned || (!a.assigned && a.fixedAssigned)
      ),
      payload.cancelReason
    );
    archiveAssetList(ctx);
    ctx.commit("clearAssetWarningList");

    if (
      payload.cancelReason !== "goingAway" &&
      ctx.state._assetListHistory.some((a) => a.assigned)
    ) {
      pushAbortedMsg(ctx);
    }
  },
};

function requestRescan(ctx: HandoverContext) {
  ctx.commit("forceAssetActiveFalseTwice");
  const requestRescanMsg: UIRequestsRescanMsg = {
    action: "requestRescan",
    payload: {},
  };
  sendToBebos(ctx, requestRescanMsg);
}

export function sendToBebos(ctx: HandoverContext, msg: MsgToBebos) {
  ctx.dispatch("sendToBebos", msg, { root: true });
}

function pushAbortedMsg(ctx: HandoverContext) {
  const abortedMsg: Snack = {
    type: "error",
    timed: false,
    ttl: 1,
    msg: "Übergabe abgebrochen",
    actionList: [
      {
        msg: "zurück",
        icon: "mdi-arrow-left",
        action: () => {
          ctx.commit("restoreHandover");
        },
      },
    ],
  };
  ctx.commit("pushSnack", abortedMsg);
}

async function reportCanceled(
  ctx: HandoverContext,
  assetsToReport: FixableAsset[],
  cancelReason: CancelReason
): Promise<void> {
  if (assetsToReport.length === 0) return;
  const currentEmployee = ctx.rootGetters["currentEmployee"] as
    | Employee
    | "annonym";
  const employeeId = currentEmployee !== "annonym" ? currentEmployee.id : null;

  // report to influx
  const point = new Point()
    .intField("asset_count", assetsToReport.length)
    .stringField("asset_list", assetsToReport.map(reduceAssetInfo))
    .stringField("cancel_reason", cancelReason);

  if (employeeId !== null) {
    point.intField("employee_id", employeeId);
  }

  const iMsg: InfluxMsg = {
    context: "store.handover",
    msg: "canceledHandover",
    point,
  };
  /*await*/
  ctx.dispatch("logToInflux", iMsg);

  // report to node
  const con = tryToGetNodeConnection(ctx);
  if (!con) {
    console.info("'reportCanceled' called but cannot request node.");
    return;
  }

  const terminalName = ctx.rootGetters["terminalName"];
  const new_val = terminalName ? `Terminal: '${terminalName}'` : "";
  for (const asset of assetsToReport) {
    con.create<AssetLog>("asset_log", {
      log_type: "CANCELED_HANDOVER",
      asset: asset.id,
      employee: employeeId,
      new_val,
    });
  }
}

async function couldManageWrongHome(ctx: HandoverContext): Promise<boolean> {
  ctx.state.wrongHome.accept = null;
  ctx.state.wrongHome.showError = false;
  ctx.state.wrongHome.assets = await ctx.dispatch(
    "getAssignedAssetsWithWrongHome"
  );
  if (ctx.state.wrongHome.assets.length === 0) return true;
  ctx.state.wrongHome.showError = true;
  await nextTick();
  while (ctx.state.wrongHome.accept == null) {
    await wait_ms(100);
  }
  const couldManage = ctx.state.wrongHome.accept;
  ctx.state.wrongHome = {
    assets: [],
    accept: null,
    showError: false,
  };
  return couldManage;
}

export function displayHandoverIfNeeded(): void {
  const path = router.currentRoute.path;
  if (["/test", handoverPath, "/warning"].includes(path)) return;
  router.push(handoverPath);
}

function displayDone(): void {
  router.push("/home");
}

function tryToGetNodeConnection(
  ctx: HandoverContext
): WattroNodeConnection | null {
  return ctx.rootGetters.tryToGetNodeConnection;
}

const module: Module<HandoverState, RootState> = {
  state,
  getters,
  actions,
  mutations,
  modules: {
    suggestion,
  },
};
export default module;
