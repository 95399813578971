<template>
  <v-card light class="pa-30 centered">
    <v-container>
      Terminal wird initialisiert

      <v-card class="pa-3 ma-3">
        <v-list>
          <v-list-item v-for="(item, i) in setupStates" :key="i">
            <v-list-item-icon>
              <v-icon v-text="iconMap[item.state]" />
            </v-list-item-icon>
            <v-list-item-content> {{ item.text }}</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
      <v-card class="ma-3 pa-3" @click="openNodeUIInPopUp()">
        Wattro Node Health status:
        <pre> {{ JSON.stringify(wattroNodeHealth, null, 1) }} </pre>
      </v-card>
      <v-card class="ma-3 pa-3" dark>
        <h1>Fehler Log</h1>
        <h2>{{ errlog }}</h2>
      </v-card>
      <v-card
        color="#ffe22b"
        class="ma-3 pa-3"
        :loading="reloadCountdown < 100"
      >
        <v-card-title v-if="reloadCountdown < 100">
          Fehler beim Initalisieren. Initalisierung wird neu gestartet in
          {{ Math.floor(reloadCountdown / 3) }}s.
        </v-card-title>
        <template slot="progress">
          <v-progress-linear
            v-model="reloadCountdown"
            color="black"
            height="10"
          ></v-progress-linear>
        </template>
        <v-card-actions>
          <v-row>
            <v-col>
              <v-btn @click="reload()" color="#ffe22b" class="pa-10" block>
                <v-icon>mdi-refresh</v-icon>
                <br />
                Initalisierung jetzt neu starten
              </v-btn>
            </v-col>
            <v-col v-if="reloadCountdown < 100">
              <v-btn @click="cancelReload()" class="pa-10" block>
                <v-icon>mdi-cancel</v-icon>
                <br />
                Countdown abbrechen
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import {
  WattroNodeConnection,
  WattroNodeConnectionCfg,
} from "@/sharedTSCode/wattroNode/connect";
import { InfluxMsg } from "@/types/storeTypes";
import { InfluxConnectionCfg } from "@/sharedTSCode/types/ui-bebos-api";
import { PostHog } from "posthog-js";

type SetupState = "notStarted" | "skipped" | "pending" | "failed" | "success";

@Component
export default class Init extends Vue {
  errlog = "";
  reloadCountdown = 100;
  reloadTimer: number | null = null;

  iconMap: Record<SetupState, string> = {
    notStarted: "mdi-circle-outline",
    skipped: "mdi-skip-next-circle-outline",
    pending: "mdi-circle-slice-5",
    failed: "mdi-circle-off-outline",
    success: "mdi-circle-slice-8",
  };

  setupStates: Record<string, { state: SetupState; text: string }> = {
    bebos: { text: "Bebos Connection", state: "notStarted" },
    wattroNodeConnectionInfo: {
      text: "Wattro Node Connection Info",
      state: "notStarted",
    },
    wattroNodeConnection: {
      text: "Wattro Node Connection",
      state: "notStarted",
    },
    terminalPref: {
      text: "Terminal Preferences",
      state: "notStarted",
    },
    logging: { text: "Logging Connection", state: "notStarted" },
  };
  wattroNodeHealth: any = { status: "no connection" };

  err: unknown = null;

  async mounted() {
    await this.doInit();
    if (this.err === null) {
      await this.$router.push("/");
      return;
    }
    this.reloadTimer = setInterval(() => {
      if (this.reloadCountdown >= 0.5) {
        this.reloadCountdown -= 0.3;
      } else {
        this.reload();
      }
    }, 100);
  }

  private async doInit() {
    try {
      await this.getBebosConnection();
      await this.setupWattroNode();
      await this.getTerminalPreferences();
      await this.setupLogging();
    } catch (e) {
      for (const stateName in this.setupStates) {
        if (this.setupStates[stateName].state == "pending") {
          this.setupStates[stateName].state = "failed";
        }
      }
      this.setError(e);
    }
  }

  private async getBebosConnection() {
    if (process.env.VUE_APP_INIT__SKIP_BEBOS) {
      this.setupStates.bebos.state = "skipped";
      console.warn("skipping bebos setup");
      this.$store.state.terminal.bebosInfo = {
        node: {
          url: process.env.VUE_APP_INIT__NODE_URL,
          key: process.env.VUE_APP_INIT__NODE_KEY,
        },
        terminal: {
          name: process.env.VUE_APP_INIT__TERMINAL_NAME,
          locked: process.env.VUE_APP_INIT__TERMINAL_LOCKED,
        },
      };
      return;
    }
    this.setupStates.bebos.state = "pending";
    await this.$store.dispatch("setupWs");
    this.setupStates.bebos.state = "success";
  }

  private async setupWattroNode() {
    this.setupStates.wattroNodeConnectionInfo.state = "pending";
    const credentials = await this.getWattroNodeCredentials();
    if (!credentials) {
      throw new Error(
        "Failed to get Wattro Node Credentials. Either connect to a terminal or set them explicitly in the env."
      );
    }
    this.setupStates.wattroNodeConnectionInfo.state = "success";

    this.setupStates.wattroNodeConnection.state = "pending";
    await this.initConnection(credentials);
    this.setupStates.wattroNodeConnection.state = "success";
  }

  private async getTerminalPreferences() {
    this.setupStates.terminalPref.state = "pending";
    await this.$store.dispatch("loadTerminalPreferences");
    this.setupStates.terminalPref.text = `Terminal '${this.$store.getters.terminalName}'`;
    this.setupStates.terminalPref.state = "success";
  }

  private async setupLogging() {
    if (process.env.VUE_APP_INIT__SKIP_LOGGING) {
      this.setupStates.logging.state = "skipped";
      console.warn("Skipping logging.");
      return;
    }
    this.setupStates.logging.state = "pending";
    await this.setupInflux();
    const ph = (this as any).$posthog as PostHog;
    ph.identify(this.$store.getters.terminalName);
    ph.startSessionRecording();
    this.setupStates.logging.state = "success";
  }

  private async setupInflux() {
    const params = this.getInfluxLoginParams();
    if (
      !params.influx.url ||
      !params.influx.token ||
      !params.influx.org ||
      !params.influx.bucket ||
      !params.location
    ) {
      return Promise.reject(
        `Missing needed Influx Parameters. Got ${JSON.stringify(params)}`
      );
    }
    await this.$store.dispatch("setupInflux", params);
    const iMsg: InfluxMsg = {
      msg: "setup done",
      context: "Init.vue",
      level: "info",
    };
    if (this.err !== null) {
      iMsg.msg = `setup Failed with ${JSON.stringify(this.err)}`;
    }
    this.$store.dispatch("logToInflux", iMsg);
  }

  private setError(e: unknown) {
    this.errlog += JSON.stringify(e, null, 1);
    this.err = e;
    console.error(e);
  }

  async initConnection(cred: WattroNodeConnectionCfg): Promise<void> {
    let conState: any = "unset";
    try {
      await this.$store.dispatch("connectToNode", cred);
      conState = this.$store.state.nodeCon.connectionState;
    } catch (e) {
      conState = e;
    }
    try {
      const con: WattroNodeConnection = await this.$store.state.nodeCon.con;
      this.wattroNodeHealth = await con.getHealthchecks();
    } catch (e) {
      conState = `unhealthy connection ${e}`;
    }

    if (conState !== "connected") {
      return Promise.reject(`connection to Node failed: ${conState}`);
    }
  }

  private async getWattroNodeCredentials(): Promise<WattroNodeConnectionCfg> {
    while (this.$store.state.terminal.bebosInfo === null) {
      await this.delay(100);
    }
    this.setupStates.wattroNodeConnectionInfo.text += " (src: bebos)";
    return this.$store.state.terminal.bebosInfo.node;
  }

  private getInfluxLoginParams(): {
    location: string;
    influx: InfluxConnectionCfg;
  } {
    const location = this.$store.state.terminal.nodeTerminal.name;
    const influx = this.$store.state.terminal.bebosInfo.influx;
    return { location, influx };
  }

  async delay(ms: number) {
    return new Promise((res) => setTimeout(res, ms));
  }

  public openNodeUIInPopUp() {
    const con: WattroNodeConnection = this.$store.state.nodeCon.con;
    if (!con) {
      this.errlog += "No Node Connection known. ";
      return;
    }
    window.open(
      con.getHealthchecksUrl(),
      "wattroNodeConnection health check",
      "width=600,height=400"
    );
  }

  public reload() {
    location.reload();
  }

  public cancelReload() {
    if (this.reloadTimer) {
      clearInterval(this.reloadTimer);
    }
    this.reloadCountdown = 100;
  }
}
</script>
