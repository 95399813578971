import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import posthogPlugin from "./plugins/posthog";

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Vue.config.productionTip = false;

if (process.env["VUE_APP_SENTRY_DSN"]) {
  Sentry.init({
    Vue,
    dsn: process.env["VUE_APP_SENTRY_DSN"],
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ["localhost", "term-ui.wattro.de", /^\//],
      }),
    ],
    tracesSampleRate: 0.3,
    logErrors: true,
    trackComponents: true,
  });
  Sentry.setTag("nodeUrl", "unknown");
  Sentry.setTag("terminalName", "unknown");
  Sentry.setTag("version", process.env["VUE_APP_VERSION"] ?? "unknown");
} else {
  console.warn("No Sentry setup possible. DSN missing.");
}

/** TRANSFORM SCROLL TO CLICK */
// Prevent scrolling on touch move events
document.addEventListener("touchmove", (e) => e.preventDefault(), {
  passive: false,
});

document.addEventListener("touchend", (e) => {
  // Prevent the touchend from bubbling
  e.preventDefault();
  e.stopPropagation();

  // Identify the elment where the touch ended on
  const changedTouch = e.changedTouches[0];
  const elem = document.elementFromPoint(
    changedTouch.clientX,
    changedTouch.clientY
  );

  // Simulate a click on the element where the touch ended
  if (elem && elem instanceof HTMLElement) {
    elem.click();
  } else {
    console.error("not a html element", elem);
  }
});

Vue.use(posthogPlugin);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
