/// set a timer multiple times but trigger it only once if setting while running
class ExtendableTimer {
  private timer: ReturnType<typeof setTimeout> = setTimeout(() => {});
  constructor(public timeout_ms: number = 500, public action: () => void) {}

  interrupt() {
    clearTimeout(this.timer);
  }

  setOrExtendTimeout() {
    this.interrupt();
    this.timer = setTimeout(() => this.action(), this.timeout_ms);
  }
}

export class TimeoutFlag {
  public flagResetTimer: ExtendableTimer;

  constructor(public readonly timeout_ms = 500, public flag = false) {
    this.flagResetTimer = new ExtendableTimer(timeout_ms, () => {
      this.flag = false;
    });
  }

  set() {
    this.flag = true;
    this.flagResetTimer.setOrExtendTimeout();
  }
}
