<template>
  <v-avatar
    :size="size"
    :color="employeeNameColor"
    style="border: 3px solid rgba(2, 4, 7, 1)"
  >
    <v-img contain :src="employee.img['main'][0].picture.thumbnail"></v-img>
  </v-avatar>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { Employee } from "src/sharedTSCode/types/nodeTypes";

@Component({ components: {} })
export default class EmployeeAvatar extends Vue {
  @Prop() employee!: Employee;
  @Prop({ default: "60" }) readonly size!: string;

  get getInitals(): string {
    return this.employee.name
      .split(" ")
      .map((word) => (word === "" ? "" : word[0].toUpperCase()))
      .join("");
  }

  get employeeNameColor(): string {
    const seed = this.employee.name.split("");
    const seedSteps = Math.floor(seed.length / 3);
    if (seedSteps < 1) {
      return "yellow";
    }
    let rgbValues = [];
    for (const i of [0, seedSteps, 2 * seedSteps]) {
      rgbValues.push(
        seed
          .slice(i, i + seedSteps)
          .reduce((acc, val) => acc + val.charCodeAt(0), 0) % 256
      );
    }
    return (
      "#" +
      rgbValues
        .map((v: number) => {
          if (v < 110) return "ee";
          let hex = v.toString(16);
          return hex.length < 2 ? "f" + hex : hex;
        })
        .join("")
    );
  }
}
</script>
