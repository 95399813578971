import { Asset } from "@/sharedTSCode/types/nodeTypes";
import { ScannedAsset } from "@/sharedTSCode/types/ui-bebos-api";
import { RootState, HandoverSuggestionState } from "@/types/storeTypes";
import {
  ActionContext,
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from "vuex";
type HandoverSuggestionContext = ActionContext<
  HandoverSuggestionState,
  RootState
>;
const state: HandoverSuggestionState = {
  show: false,
  hint: false,
  excludeFromSuggestionIdx: [],
};

const getters: GetterTree<HandoverSuggestionState, RootState> = {
  hasSuggestions(state: HandoverSuggestionState, _g, _rs, rootGetters: any) {
    return (
      rootGetters["handoverAssetList"].length >
      state.excludeFromSuggestionIdx.length
    );
  },
  suggestedAssetList(
    state: HandoverSuggestionState,
    _g,
    _rs,
    rootGetters: any
  ): Asset[] {
    const assetList: ScannedAsset[] = rootGetters["handoverAssetList"];
    return assetList
      .filter((_a, i) => !state.excludeFromSuggestionIdx.includes(i))
      .sort((a, b) => b.assignScore - a.assignScore);
  },
};

const mutations: MutationTree<HandoverSuggestionState> = {
  hideSuggestions(state: HandoverSuggestionState) {
    state.show = false;
    state.hint = false;
  },
  toggleHandoverSuggestionHint(state: HandoverSuggestionState) {
    state.hint = !state.hint;
  },
  resetHandoverSuggestion(state: HandoverSuggestionState) {
    state.show = false;
    state.hint = false;
    state.excludeFromSuggestionIdx = [];
  },
};
const actions: ActionTree<HandoverSuggestionState, RootState> = {
  showNewSuggestions(ctx: HandoverSuggestionContext): void {
    updateSuggestions(ctx);
    ctx.state.show = true;
  },
};
function updateSuggestions(ctx: HandoverSuggestionContext) {
  const assetList = ctx.getters["handoverAssetList"];
  ctx.state.excludeFromSuggestionIdx = [];
  for (let i = 0; i < assetList.length; i++) {
    if (assetList[i].assigned) {
      ctx.state.excludeFromSuggestionIdx.push(i);
    }
  }
}
const module: Module<HandoverSuggestionState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
export default module;
