import {
  WattroNodeConnection,
  WattroNodeConnectionCfg,
} from "@/sharedTSCode/wattroNode/connect";
import { RootState, NodeConState } from "@/types/storeTypes";
import {
  ActionContext,
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from "vuex";
type NodeConContext = ActionContext<NodeConState, RootState>;
const state: NodeConState = {
  connectionState: "connecting",
  con: null,
};
const getters: GetterTree<NodeConState, RootState> = {
  nodeCon(state: NodeConState): WattroNodeConnection {
    if (!state.con) {
      throw new Error("Connection not set up.");
    }
    return state.con;
  },

  tryToGetNodeConnection(state: NodeConState): WattroNodeConnection | null {
    if (!state.con) {
      console.info("Tried to access connection - not set up.");
    }
    return state.con;
  },

  initialNodeConnectionDone(state: NodeConState): boolean {
    return state.connectionState == "connected";
  },
};
const mutations: MutationTree<NodeConState> = {
  setWattroNodeConnectionState(
    state: NodeConState,
    newState: "connecting" | "connected" | "failed"
  ) {
    state.connectionState = newState;
  },
};

const actions: ActionTree<NodeConState, RootState> = {
  async connectToNode(
    ctx: NodeConContext,
    cred: WattroNodeConnectionCfg
  ): Promise<void> {
    state.connectionState = "connecting";
    state.con = new WattroNodeConnection(cred);
    if (await state.con.canConnect()) {
      state.connectionState = "connected";
    } else {
      state.connectionState = "failed";
    }
  },
};
const module: Module<NodeConState, RootState> = {
  state,
  mutations,
  actions,
  getters,
};
export default module;
