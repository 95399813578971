<template>
  <v-dialog
    @click:outside.prevent="hideEmployeeLoginDialog()"
    v-model="showDialog"
    overlay-color="black"
    overlay-opacity=".6"
    width="92vw"
    dark
    style="height: 100%"
    transition="fade-transition"
  >
    <v-card class="pa-0 ma-0" style="background: #cdcdcd">
      <v-app-bar light height="100" flat>
        <span class="text-h4">Anmeldung</span>
        <v-spacer />
        <v-btn
          fab
          color="darkBlue"
          dark
          class="ma-0 pa-0"
          elevation="0"
          @click="hideEmployeeLoginDialog()"
        >
          <v-icon x-large>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <template v-if="force_key_card">
        <v-row class="pa-0 ma-0" style="overflow: hidden">
          <v-col cols="12" class="px-5 py-10">
            <v-container fluid class="text-h2 darkBlue--text">
              Bitte halte einen Transponder/KeyCard vor das
              <v-icon x-large light style="vertical-align: baseline">
                mdi-lock-open
              </v-icon>
              Symbol am Terminal, um dich anzumelden.
              <br />
              Angezeigte Geräte werden Dir automatisch zugewiesen.
            </v-container>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <Searchbar
          labelText="Suche"
          :showMenu="false"
          @search-input="searchInEmployeeList"
        />

        <v-row class="pa-0 ma-0" style="min-height: 800px; overflow: hidden">
          <v-col cols="12" class="pr-5 pl-5">
            <EmployeeListItem
              v-for="employee in employeeListCurrentPage"
              :key="employee.id"
              :employee="employee"
            />
            <v-container
              v-if="getPageCount() < 1"
              fluid
              class="text-h2 darkBlue--text"
            >
              keine Treffer...</v-container
            >
          </v-col>
        </v-row>

        <!-- Paginator start -->
        <div class="text-center">
          <v-container fluid style="min-height: 300px">
            <v-row justify="center">
              <v-col cols="12" class="flex-row d-flex align-center">
                <v-container class="max-width">
                  <v-pagination
                    v-model="page"
                    dark
                    :length="getPageCount()"
                    :total-visible="5"
                    v-show="getPageCount() > 1"
                  />
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <!-- Paginator end -->
      </template>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Watch, Component, Vue } from "vue-property-decorator";
import { mapState, mapMutations } from "vuex";
import Searchbar from "@/components/Keyboard/Searchbar.vue";
import EmployeeListItem from "@/components/Employee/ListItem.vue";

import { Employee } from "@/sharedTSCode/types/nodeTypes";
import _ from "lodash";

@Component({
  components: { EmployeeListItem, Searchbar },
  computed: {
    ...mapState({
      showDialog: (store: any) => store.employee.showLoginDialog,
      employeeList: (store: any) => store.employee.list,
      force_key_card: (store: any) =>
        store.terminal.preferences.force_key_card_login,
    }),
  },
  methods: {
    ...mapMutations(["hideEmployeeLoginDialog"]),
  },
})
export default class EmployeeDialog extends Vue {
  public showDialog!: boolean;
  public page = 1;
  public itemsPerPage = 6;
  public employeeListCurrentPage: Employee[] = [];

  private employeeList!: Employee[];
  private employeeListSearchResult: Employee[] = [];

  async created(): Promise<void> {
    this.searchInEmployeeList();
    this.setEmployeeListCurrentPage();
  }

  getPageCount(): number {
    return Math.ceil(this.employeeListSearchResult.length / this.itemsPerPage);
  }

  @Watch("employeeListSearchResult")
  resetPage(): void {
    this.page = 1;
  }

  @Watch("page")
  @Watch("employeeListSearchResult")
  setEmployeeListCurrentPage(): void {
    this.employeeListCurrentPage = this.employeeListSearchResult.slice(
      (this.page - 1) * this.itemsPerPage,
      this.page * this.itemsPerPage
    );
  }

  @Watch("employeeList")
  public searchInEmployeeList(qry?: string): void {
    if (qry === "" || typeof qry !== "string") {
      this.employeeListSearchResult = this.employeeList;
      return;
    }
    this.employeeListSearchResult = this.employeeList.filter((employee) => {
      return employee.name.toLowerCase().includes(qry.toLowerCase());
    });
  }
}
</script>

<style scoped>
.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: rgb(93, 102, 109) !important;
}

.v-dialog__content {
  display: block;
  margin-top: 160px;
}
</style>
