import { NodeId, Store } from "@/sharedTSCode/types/nodeTypes";
import { WattroNodeConnection } from "@/sharedTSCode/wattroNode/connect";
import { RootState, AssetStoreState } from "@/types/storeTypes";
import { ActionContext, ActionTree, Module } from "vuex";
type assetStoreContext = ActionContext<AssetStoreState, RootState>;

const state: AssetStoreState = { list: [] };

const actions: ActionTree<AssetStoreState, RootState> = {
  async getStore(ctx: assetStoreContext, id: NodeId): Promise<Store> {
    const storedStore = ctx.state.list.find((e) => e.id === id);
    if (storedStore) return storedStore;
    const con = ctx.rootGetters
      .tryToGetNodeConnection as WattroNodeConnection | null;
    if (!con) {
      return Promise.reject(new Error("getStore failed: Cannot request node."));
    }
    const fetchedStore = await con.getById<Store>("store", id);
    if (!fetchedStore)
      return Promise.reject(new Error(`Failed to fetch Store with id ${id}`));
    ctx.state.list.push(fetchedStore);
    return fetchedStore;
  },
};

const module: Module<AssetStoreState, RootState> = { state, actions };
export default module;
