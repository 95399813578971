<template>
  <div v-if="displayable">
    <v-chip :color="color" class="white--text mt-2 ml-2">
      <div class="text-caption d-block text-truncate">
        <span>{{ title }}</span>
      </div>
    </v-chip>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Asset } from "@/sharedTSCode/types/nodeTypes";

@Component({ components: {} })
export default class AssetFlag extends Vue {
  @Prop() flag!: keyof Asset["flags"];
  humanReadable: Record<Partial<keyof Asset["flags"]>, string> = {
    inspection_needed: "Prüfung nötig",
    checkout_warning: "",
    has_started_deployment: "im Einsatz",
    is_deactivated: "deaktiviert",
    reservation: "reserviert",
    unresolved_issue: "Defekt",
  };

  get displayable(): boolean {
    return this.flag !== "checkout_warning";
  }

  get color(): string {
    return "red";
  }

  get title(): string {
    return this.humanReadable[this.flag];
  }
}
</script>
