<template>
  <v-container class="pa-0" rounded-lg style="height: 100%; aspect-ratio: 1/1">
    <v-app-bar
      flat
      class="pa-0 border-radius-top-15px"
      style="height: 10%; background: white; z-index: 2"
    />

    <div style="height: 70%; background: white"></div>

    <v-row
      class="ma-0 border-radius-bottom-15px pa-5"
      style="height: 20%; background: #808080"
    >
      <v-progress-linear :indeterminate="true" color="grey" height="40" />
      <!-- text -->
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class AssetSkel extends Vue {}
</script>

<style scoped>
.border-radius-top-15px {
  border-top-right-radius: 15px !important;
  border-top-left-radius: 15px !important;
}

.border-radius-bottom-15px {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
</style>
