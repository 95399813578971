<template>
  <v-card
    flat
    class="roundet-lg"
    style="
      background: #bdbdbd;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    "
  >
    <div v-if="active" class="d-flex flex-column align-center">
      <v-btn
        @click="activateDialog()"
        class="btn-add-asset"
        :class="{
          'btn-highlight': addPossible,
        }"
      >
        <v-icon x-large style="font-size: 60px">mdi-plus</v-icon>
      </v-btn>
      <h3 class="h3-grey-highlight mt-3" style="font-size: 25px">
        Gerät hinzufügen
      </h3>
    </div>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters(["unassignedAssetList"]),
  },
})
export default class TileAdd extends Vue {
  @Prop() public active!: boolean;

  private unassignedAssetList!: unknown[];

  get addPossible(): boolean {
    return this.active && this.unassignedAssetList.length > 0;
  }

  activateDialog() {
    this.$store.dispatch("showNewSuggestions");
  }
}
</script>

<style scoped>
.btn-add-asset {
  height: 80px !important;
  width: 120px !important;
  border-radius: 15px !important;
  background: #808080 !important;
  color: #cdcdcd !important;
}

.btn-highlight {
  background: #ffe22b !important;
  color: black !important;
}

.h3-grey-highlight {
  color: #525252 !important;
  opacity: 1 !important;
}
</style>
