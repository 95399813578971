import qs from "qs";
import axios, { Axios, AxiosRequestConfig, AxiosResponse } from "axios";
import { LabelInfo, NodeType, Preference } from "../types/nodeTypes";
import { api, ApiDataKeys, ApiEndpointName } from "../utils/apiResolver";

interface KeyCfg {
  url: string;
  key: string;
}

export type HealthCheckResponse = {
  auth_status: {
    has_permission: boolean;
    api_key_valid: boolean;
    user_logged_in: boolean;
  };
  cache_default: boolean;
  central_connection_settings_valid: boolean;
  db: boolean;
};

export type WattroNodeConnectionCfg = KeyCfg;

export class WattroNodeConnection {
  call: Axios;

  constructor(cfg: WattroNodeConnectionCfg) {
    this.call = axios.create({
      baseURL: cfg.url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Api-Key ${cfg.key}`,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, {
          arrayFormat: "comma",
        }),
    });
  }

  getHealthchecksUrl(): string {
    return urlConcat(this.call.getUri(), api.healthchecks.path);
  }

  async getHealthchecks(): Promise<HealthCheckResponse> {
    const res = await this.call.get(api.healthchecks.path);
    return res.data;
  }

  async canConnect(): Promise<boolean> {
    try {
      const healthchecks = await this.getHealthchecks();
      return healthchecks.auth_status.has_permission;
    } catch (e) {
      return false;
    }
  }

  async getListData<T extends NodeType = any>(
    apiName: ApiEndpointName,
    params?: Record<string, any>,
    cfg?: AxiosRequestConfig
  ): Promise<T[]> {
    const apiPoint = api[apiName];
    return this.getRawList(apiName, params, cfg).then(
      (result) => result.data[ApiDataKeys.results][apiPoint.setName]
    );
  }

  async getListDataInContext<T extends NodeType = any>(
    apiName: ApiEndpointName,
    params?: Record<string, any>,
    cfg?: AxiosRequestConfig
  ): Promise<{
    count: number;
    next: null | string;
    previous: null | string;
    data: T[];
  }> {
    const apiPoint = api[apiName];
    return this.getRawList(apiName, params, cfg).then((raw) => {
      const count = Number(raw.data[ApiDataKeys.count]);
      const data = raw.data[ApiDataKeys.results][apiPoint.setName];
      return {
        count,
        next: raw.data[ApiDataKeys.next],
        previous: raw.data[ApiDataKeys.previous],
        data,
      };
    });
  }

  async getRawList(
    apiName: ApiEndpointName,
    params: Record<string, any> = {},
    cfg: AxiosRequestConfig = {}
  ): Promise<AxiosResponse> {
    cfg.params = params;
    const apiPoint = api[apiName];
    return this.call.get(apiPoint.path, cfg);
  }

  async update<T extends { id: number } = any>(
    apiName: ApiEndpointName,
    entity: T
  ): Promise<T> {
    return this.call
      .patch(`${api[apiName].path}${entity.id}/`, entity)
      .then((res) => res.data);
  }

  async setSeen(data: {
    asset_list: number[];
    position: undefined | { latitude: string; longitude: string };
    terminal: number | undefined;
    employee: number | undefined;
  }) {
    return this.call
      .post(`${api.asset.path}set_seen/`, data)
      .then((res) => res.data);
  }

  async create<T = any>(
    apiName: ApiEndpointName,
    entity: Partial<T>
  ): Promise<T> {
    return this.call
      .post(`${api[apiName].path}`, entity)
      .then((res) => res.data);
  }

  async getById<T extends { id: number } = any>(
    apiName: ApiEndpointName,
    idOrEntity: number | T
  ): Promise<T | undefined> {
    const id = typeof idOrEntity === "number" ? idOrEntity : idOrEntity.id;
    const apiInfo = api[apiName];
    return this.call
      .get(`${apiInfo.path}${id}/`)
      .then((res) => res.data[apiInfo.setName][0])
      .catch((e) => {
        console.warn(`Failed to get ${id} on ${apiInfo.name}:`, e);
        return undefined;
      });
  }

  async requestLabelInfo(epcList: string[]): Promise<LabelInfo[]> {
    return this.call
      .post(`${api.label.path}request_info/`, { epc_list: epcList })
      .then((res) => res.data)
      .catch((e) => {
        console.warn(`Failed to request Label Info:`, e);
        return [];
      });
  }
  async getPreference(
    prefIdentifier: keyof Preference
  ): Promise<Preference[keyof Preference]> {
    return this.call
      .get(`/pref/${prefIdentifier}/`)
      .then((res) => res.data.value);
  }
}

export function urlConcat(base: string, path: string): string {
  return (base + "/" + path).replace(
    /([^:])(\/\/+)/g, // remove double slashes if set
    "$1/"
  );
}
