<template>
  <svg
    id="wattro-scan-icon"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 198.43 116.73"
  >
    <g id="LWPOLYLINE">
      <path
        class="cls-1"
        d="M22.15.56l6.81,5.11c1.22.91,1.5,2.63.63,3.87-20.55,29.29-20.55,68.36,0,97.65.87,1.24.58,2.96-.63,3.87l-6.81,5.11c-1.29.97-3.11.66-4.04-.66C-6.04,81.26-6.04,35.47,18.11,1.23c.93-1.32,2.75-1.63,4.04-.66Z"
      />
    </g>
    <g id="LWPOLYLINE-2" data-name="LWPOLYLINE">
      <path
        class="cls-1"
        d="M50.52,21.84l6.81,5.11c1.2.9,1.49,2.59.65,3.84-11.16,16.68-11.16,38.48,0,55.15.84,1.25.55,2.93-.65,3.84l-6.81,5.11c-1.3.97-3.15.66-4.06-.68-14.7-21.62-14.7-50.07,0-71.69.91-1.34,2.76-1.65,4.06-.68Z"
      />
    </g>
    <g id="LWPOLYLINE-3" data-name="LWPOLYLINE">
      <rect
        class="cls-1"
        x="91.7"
        y="50.85"
        width="15.03"
        height="15.03"
        rx="2.83"
        ry="2.83"
      />
    </g>
    <g id="LWPOLYLINE-4" data-name="LWPOLYLINE">
      <path
        class="cls-1"
        d="M151.97,22.52c14.7,21.62,14.7,50.07,0,71.69-.91,1.34-2.76,1.65-4.06.68l-6.81-5.11c-1.2-.9-1.49-2.59-.65-3.84,11.16-16.68,11.16-38.48,0-55.15-.84-1.25-.55-2.93.65-3.84l6.81-5.11c1.3-.97,3.15-.66,4.06.68Z"
      />
    </g>
    <g id="LWPOLYLINE-5" data-name="LWPOLYLINE">
      <path
        class="cls-1"
        d="M180.32,1.23c24.14,34.25,24.14,80.03,0,114.28-.93,1.32-2.75,1.63-4.04.66l-6.81-5.11c-1.22-.91-1.5-2.63-.63-3.87,20.55-29.29,20.55-68.36,0-97.65-.87-1.24-.58-2.96.63-3.87l6.81-5.11c1.29-.97,3.11-.66,4.04.66Z"
      />
    </g>
    <path
      class="cls-1"
      d="M101.51,23c-21.38-1.35-39.01,16.28-37.66,37.66,1.11,17.63,15.42,31.94,33.06,33.06,21.38,1.35,39.01-16.28,37.66-37.66-1.11-17.63-15.42-31.94-33.06-33.06ZM101.38,79.52c-13.45,1.33-24.65-9.88-23.32-23.32.99-9.95,9.03-18,18.98-18.98,13.45-1.33,24.65,9.88,23.32,23.32-.99,9.95-9.03,18-18.98,18.98Z"
    />
  </svg>
</template>

<script>
export default {
  name: "ScanIcon",
};
</script>

<style scoped>
.cls-1 {
  fill: white;
  stroke-width: 0px;
}
.theme--light .cls-1 {
  fill: #000;
}

.v-bottom-navigation .cls-1 {
  fill: rgba(255, 255, 255, 0.7);
}

.v-bottom-navigation .v-btn--active .cls-1 {
  fill: #ffe22b;
}

.v-bottom-navigation #wattro-scan-icon {
  width: 45px;
  height: 45px;
}
</style>
